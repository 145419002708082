import { Button, Tooltip } from "antd";

import Select from "common/Select";
import Input from "common/Input";
import MultiSelect from "common/MultiSelect";
import { EyeSvg, HideEyeSvg } from "common/Icons";
import Permitted from "common/Permitted";
import { mockedActionIds, mockedUnitIds } from "utils/models";
import { convertDateToUserTimezoneNoFormat, generateColumnWidth } from "utils/helpers";

const setDummyColor = text => {
	let count = 1;
	let newText = text;
	while (newText.includes("**")) {
		newText = newText.replace("**", "");
		if (count === 2) {
			return true;
		}
		count++;
	}
};

const renderContent = (content, { type, gameTypeName, date }) => {
	const time = date ? (
		<span className="mr-8">{`[${convertDateToUserTimezoneNoFormat(date)?.format("DD/MM/YYYY HH:mm")}]`}</span>
	) : (
		""
	);

	if (type === 2) {
		return (
			<span>
				{time} {gameTypeName}, {content}
			</span>
		);
	}
	if (type === 0) {
		return (
			<div>
				{time}
				<span style={setDummyColor(content) ? { color: "red" } : {}}>{content}</span>
			</div>
		);
	}
	return (
		<span>
			{time} {content}
		</span>
	);
};

export const tableColumns = actionCallback => [
	{
		title: "Chat Name",
		key: "chatName",
		dataIndex: "chatName",
		width: generateColumnWidth({ columnType: "chatName", title: "Chat Name" })
	},
	{
		title: "Partner Name",
		key: "partnerName",
		dataIndex: "partnerName",
		width: generateColumnWidth({ columnType: "partnerName", title: "Partner Name" })
	},
	{
		title: "Partner Player ID",
		key: "partnerPlayerId",
		dataIndex: "partnerPlayerId",
		width: generateColumnWidth({ columnType: "partnerPlayerId", title: "Partner Player ID" })
	},
	{
		title: "Nickname",
		key: "nickName",
		dataIndex: "nickName",
		width: generateColumnWidth({ columnType: "nickName", title: "Nickname" })
	},
	{
		title: "Message",
		key: "content",
		dataIndex: "content",
		width: generateColumnWidth({ columnType: "content", title: "Message", minWidth: 400 }),
		render: renderContent
	},
	{
		title: "Profanity Count",
		key: "profanityCount",
		dataIndex: "profanityCount",
		width: generateColumnWidth({ columnType: "profanityCount", title: "Profanity Count" })
	},
	{
		title: "Actions",
		width: 150,
		align: "right",
		fixed: "right",
		render: ({ hidden, id: messageId }) => {
			return (
				<Permitted
					parentUnitId={mockedUnitIds.Chat}
					unitId={mockedUnitIds.ChatMessages}
					actionId={mockedActionIds.edit}
				>
					<Tooltip title={hidden ? "Show message in the chat" : "Hide message from the Chat"}>
						<Button
							onClick={() => actionCallback({ messageId })}
							type="text"
							icon={hidden ? <HideEyeSvg /> : <EyeSvg />}
						/>
					</Tooltip>
				</Permitted>
			);
		}
	}
];

export const tableFilters = [
	{
		Component: MultiSelect,
		name: "chatNames",
		label: "Chat name",
		isRequired: true,
		help: "Please select chat name(s)!",
		componentProps: {
			placeholder: "Select Chat(s)",
			withSearch: true
		}
	},
	{
		Component: Input,
		name: "partnerPlayerId",
		label: "Partner Player ID/Nickname",
		componentProps: {
			placeholder: "Select Partner(s)",
			withSearch: true
		}
	},
	{
		Component: Select,
		name: "profanityCounts",
		label: "Profanity Count",
		componentProps: {
			placeholder: "Select Game(s)",
			withSearch: true
		}
	}
];

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ACCOUNT, REGISTER_OPERATOR, SET_LAST_ACTIVITY } from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const accountApiSlice = createApi({
	reducerPath: "account",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${ACCOUNT}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ mutation }) {
		return {
			registerOperator: mutation({
				query: body => ({
					url: REGISTER_OPERATOR,
					method: "POST",
					body
				})
			}),
			setLastActivity: mutation({
				query: () => ({
					url: SET_LAST_ACTIVITY,
					method: "POST"
				})
			})
		};
	}
});

export const { useRegisterOperatorMutation, useSetLastActivityMutation } = accountApiSlice;

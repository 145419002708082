import { useMemo, memo, useState } from "react";
import { TreeSelect } from "antd";
import PropTypes from "prop-types";
import ErrorBoundary from "../ErrorBoundary";
import "./index.less";

const { SHOW_PARENT } = TreeSelect;

function CustomTreeSelect({
	placeholder,
	onChange,
	style,
	className,
	options,
	data,
	disabled,
	maxTagCount,
	withSearch,
	name,
	status,
	form,
	childrenMapKey
}) {
	if (!Array.isArray(options)) return null;
	const [checkedKeys, setCheckedKeys] = useState(data);

	const { memoPartners, allPartnerIds } = useMemo(() => {
		const ids = [];
		const list = [
			{
				title: "Select All",
				key: "all",
				value: "all",
				disableCheckbox: disabled,
				children: options.map(item => ({
					title: item.name,
					key: `instance_${item.id || item.name}`,
					value: `instance_${item.id || item.name}`,
					disableCheckbox: disabled,
					children:
						item[childrenMapKey]?.map(partner => {
							ids.push(partner.id);
							return {
								key: partner.id,
								title: partner.name,
								disableCheckbox: disabled,
								value: partner.id
							};
						}) || []
				}))
			}
		];

		return { memoPartners: list, allPartnerIds: ids };
	}, [options]);

	const onCheck = checkedKeysValue => {
		let partnerIds = [];

		checkedKeysValue.forEach(item => {
			if (item === "all") {
				partnerIds = allPartnerIds;
			} else if (typeof item === "number") {
				partnerIds.push(item);
			} else {
				const selectedInstance = options.find(elem => `instance_${elem.id || elem.name}` === item);
				partnerIds.push(...selectedInstance.partners.map(elem => elem.id));
			}
		});

		setCheckedKeys(checkedKeysValue);
		onChange({ name, value: partnerIds }, "tree", form);
	};

	return (
		<ErrorBoundary>
			<TreeSelect
				showSearch={withSearch}
				name={name}
				placeholder={placeholder}
				treeDefaultExpandedKeys={["all"]}
				className={`${className} custom-multi-select multi-select-tree`}
				popupClassName="multi-select-tree-popup"
				style={style}
				size="middle"
				showArrow
				maxTagCount={maxTagCount}
				treeData={memoPartners}
				treeCheckable
				showCheckedStrategy={SHOW_PARENT}
				value={checkedKeys}
				status={status}
				onChange={onCheck}
				filterTreeNode={(input, treeNode) => treeNode.title.toLowerCase().includes(input.toLowerCase())}
			/>
		</ErrorBoundary>
	);
}

CustomTreeSelect.defaultProps = {
	placeholder: "Select",
	className: "",
	name: "",
	style: {},
	options: [],
	data: [],
	onChange: () => {},
	disabled: false,
	maxTagCount: 1,
	withSearch: true,
	status: "",
	form: { setFieldValue: () => {} },
	childrenMapKey: "partners"
};

CustomTreeSelect.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	style: PropTypes.object,
	options: PropTypes.array,
	data: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	maxTagCount: PropTypes.number,
	withSearch: PropTypes.bool,
	status: PropTypes.string,
	childrenMapKey: PropTypes.string,
	form: PropTypes.object
};

export default memo(CustomTreeSelect);

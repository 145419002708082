import * as React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null
		};
	}

	componentDidCatch(error) {
		this.setState({
			error
		});
	}

	render() {
		const { error } = this.state;
		const { children } = this.props;
		return error ? <Alert type="error" message="Crashed!" /> : children;
	}
}

ErrorBoundary.defaultProps = {
	children: null
};

ErrorBoundary.propTypes = {
	children: PropTypes.node
};

export default ErrorBoundary;

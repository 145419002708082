import { memo } from "react";
import PropTypes from "prop-types";
import { Popover } from "antd";
import Dots from "../Dots";
import ErrorBoundary from "../ErrorBoundary";
import "./index.css";

function ShowMore({ list }) {
	if (!Array.isArray(list)) return list;

	return (
		<ErrorBoundary>
			<div className="show-more-container">
				<span>{list[0]}</span>
				{list.length > 1 && (
					<Popover
						content={list.slice(1).map(name => (
							<p key={name}>{name}</p>
						))}
						trigger="click"
						overlayClassName="show-more-overlay"
					>
						<span>
							<Dots />
						</span>
					</Popover>
				)}
			</div>
		</ErrorBoundary>
	);
}

ShowMore.defaultProps = {
	list: []
};

ShowMore.propTypes = {
	list: PropTypes.array
};

export default memo(ShowMore);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	DASHBOARD,
	COMPARE_STATISTICS,
	GET_DASHBOARD_FILTER_FOR_TABLE,
	GET_DASHBOARD_FILTER_FOR_CHART,
	GET_GAME_REPORT_WITH_PARTNERS,
	GET_TOP_GAMES_PIE_CHART,
	GET_TOP_PARTNERS_PIE_CHART,
	GET_BAR_CHART
} from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const dashboardApiSlice = createApi({
	reducerPath: "dashboard",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${DASHBOARD}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getDashboardTableFilter: query({
				query: () => GET_DASHBOARD_FILTER_FOR_TABLE
			}),
			getDashboardChartFilter: query({
				query: () => GET_DASHBOARD_FILTER_FOR_CHART
			}),
			compareStatistics: mutation({
				query: body => ({
					url: COMPARE_STATISTICS,
					method: "POST",
					body
				})
			}),
			getTopGamesPieChart: mutation({
				query: body => ({
					url: GET_TOP_GAMES_PIE_CHART,
					method: "POST",
					body
				})
			}),
			getTopPartnersPieChart: mutation({
				query: body => ({
					url: GET_TOP_PARTNERS_PIE_CHART,
					method: "POST",
					body
				})
			}),
			getBarChart: mutation({
				query: body => ({
					url: GET_BAR_CHART,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useCompareStatisticsMutation,
	useGetDashboardChartFilterQuery,
	useGetDashboardTableFilterQuery,
	useGetTopGamesPieChartMutation,
	useGetTopPartnersPieChartMutation,
	useGetBarChartMutation
} = dashboardApiSlice;

import { useMemo, useCallback, useRef } from "react";
import HeaderFilters from "common/HeaderFilters";
import { useFilters } from "hooks/useFilters";
import { useTable } from "hooks/useTable";
import TableWithPagination from "common/TableWithPagination";
import { mockedUnitIds } from "utils/models";
import { ImportOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from "hooks/useModal";
import CustomModal from "common/Modal";
import { notification } from "antd";
import PageActionButton from "common/PageActionButton";
import {
	useAddProfanityMutation,
	useGetProfanitiesListMutation,
	useImportProfanitiesMutation,
	useUpdateProfanityMutation
} from "app/services/chutApiSlice";
import { modalTypes, tableColumns, tableFilters } from "./constants";
import AddProfanity from "./components/AddProfanity";
import ImportModal from "./components/ImportModal";

function ProfanityLibrary() {
	const [modals, openModal, closeModal] = useModal();
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] = useFilters(
		() => {}
	);
	const [getTableData, { isLoading, data, pageSize, pageIndex, handlePageChange, reset }] = useTable(
		useGetProfanitiesListMutation,
		filtersQueryData
	);
	const newProfanityRef = useRef("");
	const [importFile, { isLoading: importLoading }] = useImportProfanitiesMutation();
	const [addProfanity, { isLoading: addLoading }] = useAddProfanityMutation();
	const [updateProfanity, { isLoading: updateLoading }] = useUpdateProfanityMutation();

	const handleApply = () => getTableData({ word: selectedFiltersGroup.search });
	const handleReset = useCallback(() => {
		reset();
		setSelectedFiltersGroup({});
	}, [reset]);

	const updateProfanityById = profanity =>
		updateProfanity(profanity).then(res => {
			if (res.data.hasError === false) {
				handleApply();
				notification.success({
					message: "Successfuly updated!"
				});
			}
		});
	const tableColumnsMemo = useMemo(() => tableColumns(updateProfanityById, updateLoading), [updateProfanity]);

	const addNewProfanity = () => {
		const word = newProfanityRef.current.trim();
		if (word.length) {
			addProfanity({ word }).then(res => {
				if (res.data.hasError === false) {
					closeModal(modalTypes.add);
					notification.success({
						message: "Successfuly created!"
					});
				}
			});
		}
	};

	return (
		<section className="content-container pt-24">
			<HeaderFilters
				withReset
				withSubmit
				filtersComponentData={tableFilters}
				filtersQueryData={filtersQueryData}
				handleReset={handleReset}
				handleApply={handleApply}
				handleChange={handleSelectChange}
				selectedFiltersGroup={selectedFiltersGroup}
				setSelectedFiltersGroup={setSelectedFiltersGroup}
				disabledApplay={isLoading}
			>
				<PageActionButton
					type="primary"
					actionId={1}
					parentUnitId={mockedUnitIds.Chat}
					unitId={mockedUnitIds.ProfanityLibrary}
					icon={<PlusOutlined />}
					handleClick={() => openModal(modalTypes.add)}
					text="Add Profanity"
				/>
			</HeaderFilters>

			<div className="flex justify-end export-import">
				<PageActionButton
					handleClick={() => openModal(modalTypes.import)}
					actionId={1}
					parentUnitId={mockedUnitIds.ContentManagement}
					unitId={mockedUnitIds.Translations}
					text="Import"
					type="default"
					icon={<ImportOutlined />}
				/>
			</div>

			<TableWithPagination
				isLoading={isLoading}
				columns={tableColumnsMemo}
				data={data?.data}
				totalCount={data?.totalCount}
				pageSize={pageSize}
				currentPage={pageIndex}
				setCurrentPage={handlePageChange}
				scroll={{ y: 600 }}
				rowKey="id"
			/>

			{modals[modalTypes.add]?.open && (
				<CustomModal
					open
					onOk={addNewProfanity}
					title="Add Profanity"
					onCancel={() => closeModal(modalTypes.add)}
					okText="Add"
					loading={addLoading}
					okButtonProps={addLoading}
					bodyStyle={{
						paddingTop: 30
					}}
					modalWidth={400}
					body={<AddProfanity newProfanityRef={newProfanityRef} />}
				/>
			)}

			{modals[modalTypes.import]?.open && (
				<ImportModal closeModal={() => closeModal(modalTypes.import)} importFile={importFile} loading={importLoading} />
			)}
		</section>
	);
}

export default ProfanityLibrary;

import { useCallback } from "react";
import { notification } from "antd";
import {
	useGetFilesMutation,
	useDownloadFileMutation,
	useExportFilesMutation
} from "app/services/storageManagementApiSlice";
import { useModal } from "hooks/useModal";
import { blobObjectDownloader } from "utils/helpers";
import ModalWrapper from "./ModalWrapper";
import ExpandableTable from "./ExpandableTable";
import classNames from "./index.module.less";

function StorageManagement() {
	const [modals, openModal, closeModal] = useModal();
	const [getFiles, { isLoading: fileIsLoading }] = useGetFilesMutation();
	const [downloadFile, { isLoading: downloadFileIsLoading }] = useDownloadFileMutation();
	const [exportFiles, { isLoading: exportFileIsLoading }] = useExportFilesMutation();

	/**
	 * @param {string} options.path - The path to the file or folder to download.
	 * @param {string} options.name - The name of the file or folder.
	 * @param {"file"|"zip"} options.type - The type of download, either "file" or "zip".
	 */
	const handleDownload = useCallback(
		({ path, name, type }) =>
			(type === "file" ? downloadFile(path) : exportFiles({ path, fileName: name }))
				.then(blob => {
					const fileName = type === "file" ? name : `${name}.zip`;
					blobObjectDownloader({ data: blob.data, fileName });
				})
				.catch(err => {
					notification.error({ message: err?.message || "Something went wrong." });
				}),
		[]
	);

	const handleOpenModal = useCallback((...args) => openModal(...args), []);

	return (
		<div className={classNames.main}>
			<ExpandableTable
				getFiles={getFiles}
				openModal={handleOpenModal}
				isLoading={fileIsLoading || downloadFileIsLoading || exportFileIsLoading}
				handleDownload={handleDownload}
				classNames={classNames}
			/>
			<ModalWrapper closeModal={closeModal} modals={modals} openModal={handleOpenModal} />
		</div>
	);
}
export default StorageManagement;

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import { ExclamationCircleOutlined, LockOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";

import { resetLocaleStorge, validatePassword } from "utils/helpers";
import { changePassword } from "app/services/loginService";
import CheckValidation from "../../../common/CheckValidation";
import style from "../index.module.css";

function ChangePassword() {
	const [pass, setPass] = useState("");
	const [form] = useForm();
	const history = useHistory();

	const onFinish = values => {
		changePassword(values)
			.then(res => {
				if (!res.error) {
					resetLocaleStorge();
					history.push("/signIn");
				}
			})
			.catch(err => {
				notification.error({ message: err?.data.Message || "Something went wrong!" });
			});
	};

	return (
		<div className={style.main}>
			<div className={style.header}>
				<span className={style.headerText}>CHANGE PASSWORD</span>
			</div>
			<div className={style.auth_container}>
				<div className={style.auth_form}>
					<div className={style.headerRow}>
						<div className={style.newpasHeader}>
							<span className={style.newpasText}>Change password.</span>
						</div>
					</div>
					<Form layout="vertical" form={form} onFinish={onFinish}>
						<Form.Item
							name="currentPassword"
							rules={[
								{
									required: true,
									validator: () => {
										const oldPassword = form.getFieldValue("currentPassword");
										if (!oldPassword?.length)
											// eslint-disable-next-line prefer-promise-reject-errors
											return Promise.reject(
												<div className={style.errorMessage}>
													<ExclamationCircleOutlined />
													<p>Old Password isRequired</p>
												</div>
											);
										return Promise.resolve();
									}
								}
							]}
						>
							<Input.Password
								name="currentPassword"
								className={style.loginInput}
								type="password"
								placeholder="Enter Current Password"
								prefix={<LockOutlined style={{ color: "#4689FF", marginRight: "4px" }} />}
							/>
						</Form.Item>
						<Form.Item
							name="newPassword"
							rules={[
								{
									required: true,
									validator: () => {
										const newPassword = form.getFieldValue("newPassword");
										if (!newPassword?.length)
											// eslint-disable-next-line prefer-promise-reject-errors
											return Promise.reject(
												<div className={style.errorMessage}>
													<ExclamationCircleOutlined />
													<p>New Password isRequired</p>
												</div>
											);
										if (Object.values(validatePassword(newPassword)).some(elem => !elem)) {
											// eslint-disable-next-line prefer-promise-reject-errors
											return Promise.reject(
												<div className={style.errorMessage}>
													<ExclamationCircleOutlined />
													<p>Does not meet requirements</p>
												</div>
											);
										}
										return Promise.resolve();
									}
								}
							]}
						>
							<Input.Password
								onChange={e => {
									setPass(e.target.value);
								}}
								name="newPassword"
								className={style.loginInput}
								type="password"
								placeholder="Enter New Password"
								prefix={<LockOutlined style={{ color: "#4689FF", marginRight: "4px" }} />}
							/>
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							rules={[
								{
									required: true,
									validator: () => {
										const newPassword = form.getFieldValue("newPassword");
										const confPass = form.getFieldValue("confirmPassword");
										if (!confPass?.length) {
											// eslint-disable-next-line prefer-promise-reject-errors
											return Promise.reject(
												<div className={style.errorMessage}>
													<ExclamationCircleOutlined />
													<p>Confirm Password isRequired</p>
												</div>
											);
										}
										if (confPass !== newPassword) {
											// eslint-disable-next-line prefer-promise-reject-errors
											return Promise.reject(
												<div className={style.errorMessage}>
													<ExclamationCircleOutlined />
													<p>Please make sure your passwords match</p>
												</div>
											);
										}
										return Promise.resolve();
									}
								}
							]}
						>
							<Input.Password
								name="confirmPassword"
								className={style.loginInput}
								type="password"
								placeholder="Confirm New Password"
								prefix={<LockOutlined style={{ color: "#4689FF", marginRight: "4px" }} />}
							/>
						</Form.Item>

						<div className={style.validation}>
							<CheckValidation {...validatePassword(pass)} />
						</div>

						<Form.Item>
							<Button block type="primary" htmlType="submit">
								Change
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default ChangePassword;

import HeaderAccount from "./HeaderAccount";
import HeaderProviders from "./HeaderProviders";

function HeaderRight() {
	return (
		<div className="flex">
			<HeaderProviders />
			<HeaderAccount />
		</div>
	);
}

export default HeaderRight;

import { memo, useState } from "react";
import { Button, Form, Modal, notification, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { read, utils } from "xlsx";

import Fieldset from "common/Fieldset";
import classes from "./styles.module.less";

function ImportModal({ closeModal, importFile, loading }) {
	const [form] = useForm();
	const [excelData, setExcelData] = useState();
	const [downloadFile, setDownloadFile] = useState({});

	const removeLoadedExcel = () => {
		setExcelData({
			dataSource: [],
			fileName: ""
		});
		setDownloadFile({});
	};

	const importProfanityFile = () => {
		if (downloadFile?.file) {
			const newFormData = new FormData();
			newFormData.append("file", downloadFile.file);

			importFile(newFormData).then(res => {
				if (res.data?.hasError === false) {
					notification.success({ message: "File Imported" });
					form.resetFields();
					removeLoadedExcel();
					closeModal();
				} else {
					notification.error({ message: "Error Message" });
				}
			});
		} else {
			notification.error({ message: "Please upload Excel File!" });
		}
	};

	const addExcel = async e => {
		setExcelData(prev => ({ ...prev, fileName: e.file.name }));
		const f = await e.file.arrayBuffer();
		const wb = read(f);
		const ws = wb.Sheets[wb.SheetNames[0]];
		const downloadExcelData = utils.sheet_to_json(ws);

		setExcelData(prev => ({
			...prev,
			dataSource: downloadExcelData
		}));
		return false;
	};

	return (
		<Modal
			bodyStyle={{ padding: "52px 36px" }}
			open
			title="Import"
			closable={false}
			footer={null}
			width={641}
			onCancel={closeModal}
		>
			<Form layout="vertical" form={form} onFinish={importProfanityFile}>
				<Form.Item
					rules={[
						{
							required: true,
							message: "Please Download Excel File!"
						}
					]}
				>
					{!excelData?.dataSource?.length ? (
						<Upload.Dragger
							className={classes.uploader_import}
							beforeUpload={false}
							customRequest={e => {
								addExcel(e);
								setDownloadFile(e);
							}}
							status="error"
							showUploadList={false}
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						>
							<InboxOutlined className={classes.inbox_icon} />
							<span style={{ fontSize: "16px" }}> Browse your file or just drop it here</span>
						</Upload.Dragger>
					) : (
						<Fieldset className="border-dashed h-88">
							<div className="flex-between">
								<span>{excelData?.fileName || ""}</span>
								<Button onClick={removeLoadedExcel} icon={<DeleteOutlined style={{ color: "red" }} />} />
							</div>
						</Fieldset>
					)}
				</Form.Item>
				<Form.Item style={{ textAlign: "center", marginBottom: 0 }} className="pt-24">
					<Button
						type="link"
						disabled={loading}
						onClick={() => {
							form.resetFields();
							removeLoadedExcel();
							closeModal();
						}}
					>
						Cancel
					</Button>
					<Button htmlType="submit" type="primary" className="cms-buttons" disabled={loading}>
						Import
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default memo(ImportModal);

ImportModal.defaultProps = {
	closeModal: () => {},
	importFile: () => {},
	loading: false
};

ImportModal.propTypes = {
	closeModal: PropTypes.func,
	importFile: PropTypes.func,
	loading: PropTypes.bool
};

import { Input } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";

function AddProfanity({ newProfanityRef }) {
	const [value, setValue] = useState("");
	const [error, setError] = useState("");

	const onChange = e => {
		const name = e.target.value;
		setValue(name);
		setError("");
		newProfanityRef.current = name;
	};

	const blurInput = () => {
		if (value.trim().length === 0) {
			setError("error");
			setError("");
		}
	};

	return (
		<div>
			<p>Add Profanity</p>
			<Input value={value} status={error} onBlur={blurInput} onChange={onChange} placeholder="Type" />
		</div>
	);
}
AddProfanity.defaultProps = {
	newProfanityRef: null
};

AddProfanity.propTypes = {
	newProfanityRef: PropTypes.object
};
export default AddProfanity;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	CREATE_CURRENCY_AND_CURRENCY_CONFIG,
	CURRENCY,
	EDIT_CURRENCY_RATE_BY_CURRENCY_ID_AND_DATE_RANGE,
	EDIT_CURRENCY_RATE_BY_IDS,
	GET_ALL_CURRENCY,
	GET_CURRENCIES_RATES_BY_FILTER,
	GET_CURRENCIES_RATES_FILTERS,
	GET_CURRENCIES_WITH_PRECISION,
	GET_CURRENCY_AND_CONFIG,
	GET_CURRENCY_AND_CONFIG_LIST,
	GET_PARTNER_CURRENCIES_RATES_FILTERS,
	UPDATE_CURRENCIES_PRECISION,
	UPDATE_CURRENCY_AND_CURRENCY_CONFIG
} from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const currencyApiSlice = createApi({
	reducerPath: "currency",
	tagTypes: ["edit", "editPrecision"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}api/${CURRENCY}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getCurrenciesRatesFilters: query({
				query: () => GET_CURRENCIES_RATES_FILTERS
			}),
			getCurrenciesRatesByFilter: mutation({
				query: body => ({
					url: GET_CURRENCIES_RATES_BY_FILTER,
					method: "POST",
					body
				}),
				providesTags: ["edit"]
			}),
			getPartnerCurrenciesRatesFilters: query({
				query: partnerId => `${GET_PARTNER_CURRENCIES_RATES_FILTERS}?partnerId=${partnerId}`
			}),
			editCurrencyRateByIds: mutation({
				query: body => ({
					url: EDIT_CURRENCY_RATE_BY_IDS,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit"]
			}),
			editCurrencyRateByCurrencyIdAndDateRange: mutation({
				query: body => ({
					url: EDIT_CURRENCY_RATE_BY_CURRENCY_ID_AND_DATE_RANGE,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit"]
			}),
			getAllCurrency: query({
				query: () => GET_ALL_CURRENCY
			}),
			getCurrenciesWithPrecision: mutation({
				query: body => ({
					url: GET_CURRENCIES_WITH_PRECISION,
					method: "POST",
					body
				}),
				providesTags: ["editPrecision"]
			}),
			updateCurrenciesPrecision: mutation({
				query: body => ({
					url: UPDATE_CURRENCIES_PRECISION,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editPrecision"]
			}),
			getCurrencyAndCurrencyConfig: query({
				query: currencyId => `${GET_CURRENCY_AND_CONFIG}?currencyId=${currencyId}`
			}),
			getCurrencyAndCurrencyConfigList: mutation({
				query: body => ({
					url: GET_CURRENCY_AND_CONFIG_LIST,
					method: "POST",
					body
				})
			}),
			createCurrencyAndCurrencyConfig: mutation({
				query: body => ({
					url: CREATE_CURRENCY_AND_CURRENCY_CONFIG,
					method: "POST",
					body
				})
			}),
			updateCurrencyAndCurrencyConfig: mutation({
				query: body => ({
					url: UPDATE_CURRENCY_AND_CURRENCY_CONFIG,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useGetCurrenciesRatesFiltersQuery,
	useGetCurrenciesRatesByFilterMutation,
	useGetPartnerCurrenciesRatesFiltersQuery,
	useEditCurrencyRateByIdsMutation,
	useEditCurrencyRateByCurrencyIdAndDateRangeMutation,
	useGetAllCurrencyQuery,
	useGetCurrenciesWithPrecisionMutation,
	useUpdateCurrenciesPrecisionMutation,
	useGetCurrencyAndCurrencyConfigQuery,
	useGetCurrencyAndCurrencyConfigListMutation,
	useCreateCurrencyAndCurrencyConfigMutation,
	useUpdateCurrencyAndCurrencyConfigMutation
} = currencyApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { dataWithPaginationQuery, prepareHeaders } from "utils/helpers";
import { GET_ALL, RECIPIENT_LIST } from "../constants";

export const recipientListsApiSlice = createApi({
	reducerPath: "listsRecipient",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${RECIPIENT_LIST}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getRecipientLists: query({
				query: body => {
					return {
						url: GET_ALL,
						params: dataWithPaginationQuery(body)
					};
				}
			}),
			deleteRecipientsList: mutation({
				query: id => {
					return {
						url: `${id}`,
						method: "DELETE"
					};
				}
			}),
			getRecipientsListConfig: query({
				query: id => {
					return {
						url: `${id}`,
						method: "GET"
					};
				}
			}),
			getRecipientsListMembers: query({
				query: id => {
					return {
						url: `${id}/Members`,
						method: "GET"
					};
				}
			}),
			createRecipientsList: mutation({
				query: body => {
					return {
						method: "POST",
						body
					};
				}
			}),
			updateRecipientsList: mutation({
				query: params => {
					const { id, body } = params;
					return {
						url: `${id}`,
						method: "PUT",
						body
					};
				}
			})
		};
	}
});

export const {
	useGetRecipientListsQuery,
	useDeleteRecipientsListMutation,
	useGetRecipientsListConfigQuery,
	useGetRecipientsListMembersQuery,
	useCreateRecipientsListMutation,
	useUpdateRecipientsListMutation
} = recipientListsApiSlice;

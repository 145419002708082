import { useContext } from "react";
import { Tooltip, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import ErrorBoundary from "common/ErrorBoundary";
import { LockIcon, LogOut } from "common/Icons";
import { logOut } from "app/services/loginService";
import { resetLocaleStorge } from "utils/helpers";
import "./index.css";

const popoverContent = () => {
	const [{ user }, dispatch] = useContext(AuthContext);
	const history = useHistory();

	const logOutHandler = () => {
		logOut().then(() => {
			resetLocaleStorge();
			dispatch({ type: "SIGN_OUT" });
		});
	};

	const changePasswordHandler = () => {
		history.push("/changePassword");
	};

	return (
		<div className="header-popover-content">
			<div className="header-popover-top">
				<h3 title={user.email}>{user.email}</h3>
				{/* <p>
					<i className="icon-Account" /> My Account
				</p> */}
				<p onClick={changePasswordHandler} role="presentation">
					<LockIcon className="iconStyle" />
					Change Password
				</p>
			</div>
			<p onClick={logOutHandler} className="header-logout" role="presentation">
				<LogOut className="iconStyle" />
				Log Out
			</p>
		</div>
	);
};

function HeaderAccount() {
	return (
		<ErrorBoundary>
			<div className="header-account-main">
				<Tooltip placement="bottom" title="Account">
					<ErrorBoundary>
						<Popover
							placement="bottom"
							overlayClassName="header-account-popover"
							content={popoverContent}
							trigger="click"
						>
							<span className="header-account">
								<i className="icon-Account" />
							</span>
						</Popover>
					</ErrorBoundary>
				</Tooltip>
			</div>
		</ErrorBoundary>
	);
}

export default HeaderAccount;

import PropTypes from "prop-types";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import style from "./style.module.css";

function CheckValidation({ lower, upper, number, length }) {
	return (
		<div className={style.main}>
			<div className={style.column}>
				<p>
					<span>
						{lower ? <CheckOutlined style={{ color: "#339B22" }} /> : <CloseOutlined style={{ color: "#C52B2B" }} />}
					</span>
					&nbsp;&nbsp;One lower case character
				</p>
				<p>
					<span>
						{upper ? <CheckOutlined style={{ color: "#339B22" }} /> : <CloseOutlined style={{ color: "#C52B2B" }} />}
					</span>
					&nbsp;&nbsp;One upper case character
				</p>
			</div>
			<div className={style.column}>
				<p>
					<span>
						{number ? <CheckOutlined style={{ color: "#339B22" }} /> : <CloseOutlined style={{ color: "#C52B2B" }} />}
					</span>
					&nbsp;&nbsp;One number
				</p>

				<p>
					<span>
						{length ? <CheckOutlined style={{ color: "#339B22" }} /> : <CloseOutlined style={{ color: "#C52B2B" }} />}
					</span>
					&nbsp;&nbsp;8 character min
				</p>
			</div>
		</div>
	);
}

CheckValidation.defaultProps = {
	lower: false,
	upper: false,
	number: false,
	length: false
};

CheckValidation.propTypes = {
	lower: PropTypes.bool,
	upper: PropTypes.bool,
	number: PropTypes.bool,
	length: PropTypes.bool
};

export default CheckValidation;

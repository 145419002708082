import { memo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { EditIcon } from "common/Icons";
import classes from "./styles.module.less";

function Profanity({ name, id, updateProfanity, isLoading }) {
	const [isEdit, setIsEdit] = useState(false);
	const [error, setError] = useState("");
	const [value, setValue] = useState(name);

	const toggleEditable = () => {
		if (isEdit) {
			setValue(name);
			setError("");
		}
		setIsEdit(prev => !prev);
	};

	const changeProfanity = () => {
		if (value.trim().length > 0) {
			updateProfanity({ word: value, id });
		} else {
			setError("error");
		}
	};

	const onChangeInput = e => {
		if (!isEdit) return;
		setValue(e.target.value);
		setError("");
	};

	return (
		<div className={classes.input}>
			<div className={classes.editButtons}>
				{isEdit ? (
					<>
						<Button
							disabled={isLoading}
							size="small"
							onClick={changeProfanity}
							icon={<CheckOutlined />}
							type="text"
							shape="circle"
						/>
						<Button size="small" onClick={toggleEditable} danger icon={<CloseOutlined />} type="text" shape="circle" />
					</>
				) : (
					<Button
						data-class="edit-button"
						size="small"
						onClick={toggleEditable}
						icon={<EditIcon />}
						type="link"
						shape="circle"
					/>
				)}
			</div>
			<Input status={error} value={value} name={id} onChange={onChangeInput} readOnly={!isEdit} />
		</div>
	);
}

Profanity.defaultProps = {
	name: "",
	id: "",
	isLoading: false,
	updateProfanity: () => {}
};

Profanity.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	isLoading: PropTypes.bool,
	updateProfanity: PropTypes.func
};

export default memo(Profanity);

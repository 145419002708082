import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	ADD_PROFANITY,
	BLOCK_PLAYER,
	CHAT,
	CREATE_CHAT,
	EDIT_CHAT,
	EDIT_CHAT_STATUS,
	GET_AVATARS_LIST,
	GET_AVATARS_LIST_FILTERS,
	GET_CHATS_LIST,
	GET_CHATS_LIST_FILTERS,
	GET_CHAT_BY_ID,
	GET_CHAT_PLAYERS_LIST,
	GET_CHAT_PLAYERS_LIST_FILTERS,
	GET_CREATE_EDIT_FILTERS,
	GET_MESSAGES_LIST,
	GET_MESSAGES_LIST_FILTERS,
	GET_PROFANITIES_LIST,
	GET_STICKERS_LIST,
	GET_STICKERS_LIST_FILTERS,
	IMPORT_PROFANITIES,
	MAKE_INFLUENCER,
	RESET_PLAYER_NICKNAME,
	UPDATE_AVATARS,
	UPDATE_PROFANITY,
	UPDATE_STICKERS,
	UPDATE_MESSAGE_STATE
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const chutApiSlice = createApi({
	reducerPath: "chat",
	tagTypes: ["edit", "editChats"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${CHAT}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getMessagesListFilters: query({
				query: () => GET_MESSAGES_LIST_FILTERS
			}),
			getChatPlayersListFilters: query({
				query: () => GET_CHAT_PLAYERS_LIST_FILTERS
			}),
			getMessagesList: mutation({
				query: body => ({
					url: GET_MESSAGES_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getChatPlayersList: mutation({
				query: body => ({
					url: GET_CHAT_PLAYERS_LIST,
					method: "POST",
					body: dataWithPagination(body)
				}),
				providesTags: ["edit"]
			}),
			resetPlayerNickName: mutation({
				query: body => ({
					url: RESET_PLAYER_NICKNAME,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit"]
			}),
			blockPlayer: mutation({
				query: body => ({
					url: BLOCK_PLAYER,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit"]
			}),
			makeInfluencer: mutation({
				query: body => ({
					url: MAKE_INFLUENCER,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit"]
			}),
			getChatsListFilters: query({
				query: () => GET_CHATS_LIST_FILTERS,
				providesTags: ["editChats"]
			}),
			getChatsList: mutation({
				query: body => ({
					url: GET_CHATS_LIST,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editChats"]
			}),
			editChatStatus: mutation({
				query: body => ({
					url: EDIT_CHAT_STATUS,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editChats"]
			}),
			getCreateEditFilters: query({
				query: () => GET_CREATE_EDIT_FILTERS
			}),
			createChat: mutation({
				query: body => ({
					url: CREATE_CHAT,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editChats"]
			}),
			editChat: mutation({
				query: body => ({
					url: EDIT_CHAT,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editChats"]
			}),
			getChatById: query({
				query: chatId => ({
					url: `${GET_CHAT_BY_ID}?chatId=${chatId}`,
					method: "POST"
				})
			}),
			getProfanitiesList: mutation({
				query: body => ({
					url: GET_PROFANITIES_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			updateProfanity: mutation({
				query: body => ({
					url: UPDATE_PROFANITY,
					method: "POST",
					body
				})
			}),
			addProfanity: mutation({
				query: body => ({
					url: ADD_PROFANITY,
					method: "POST",
					body
				})
			}),
			importProfanities: mutation({
				query: body => ({
					url: IMPORT_PROFANITIES,
					method: "POST",
					body
				})
			}),
			getStickersListFilters: query({
				query: () => GET_STICKERS_LIST_FILTERS
			}),
			getStickersList: mutation({
				query: body => ({
					url: GET_STICKERS_LIST,
					method: "POST",
					body
				})
			}),
			updateStickers: mutation({
				query: body => ({
					url: UPDATE_STICKERS,
					method: "POST",
					body
				})
			}),
			updateAvatars: mutation({
				query: body => ({
					url: UPDATE_AVATARS,
					method: "POST",
					body
				})
			}),
			getAvatarsListFilters: query({
				query: () => GET_AVATARS_LIST_FILTERS
			}),
			getAvatarsList: mutation({
				query: body => ({
					url: GET_AVATARS_LIST,
					method: "POST",
					body
				})
			}),
			updateMessageState: mutation({
				query: body => ({
					url: UPDATE_MESSAGE_STATE,
					method: "PUT",
					body
				})
			})
		};
	}
});

export const {
	useGetMessagesListFiltersQuery,
	useGetChatPlayersListFiltersQuery,
	useGetMessagesListMutation,
	useGetChatPlayersListMutation,
	useResetPlayerNickNameMutation,
	useBlockPlayerMutation,
	useMakeInfluencerMutation,
	useGetChatsListFiltersQuery,
	useGetChatsListMutation,
	useEditChatStatusMutation,
	useGetCreateEditFiltersQuery,
	useCreateChatMutation,
	useEditChatMutation,
	useGetChatByIdQuery,
	useGetProfanitiesListMutation,
	useUpdateProfanityMutation,
	useAddProfanityMutation,
	useImportProfanitiesMutation,
	useGetStickersListFiltersQuery,
	useGetStickersListMutation,
	useUpdateAvatarsMutation,
	useUpdateStickersMutation,
	useGetAvatarsListFiltersQuery,
	useGetAvatarsListMutation,
	useUpdateMessageStateMutation
} = chutApiSlice;

import { useState } from "react";
import { pageSize } from "utils/constants";
import { mutateSelectValues } from "utils/helpers";

export function useTable(useMutation, filtersQueryData, withoutPagination) {
	const [state, setState] = useState({
		pagination: { pageIndex: 0, pageSize }
	});

	const [getTableData, { data, error, isLoading, isFetching, reset }] = useMutation();

	const handlePageChange = (index, size, isNullCase = true) => {
		setState(prev => ({ ...prev, pagination: { pageIndex: index - 1, pageSize: size } }));

		getTableData(
			mutateSelectValues(
				{ ...state, pagination: { pageIndex: index - 1, pageSize: size } },
				filtersQueryData,
				isNullCase
			)
		);
	};

	const getTableDataWithPagination = (params = {}, isNullCase = true) => {
		const cloneParams = { ...params };
		delete cloneParams.pageIndex;
		const newState = {
			pagination: {
				pageIndex: params.pageIndex ? params.pageIndex - 1 : 0,
				pageSize: state.pagination.pageSize || pageSize
			},
			...cloneParams
		};
		setState(newState);
		getTableData(mutateSelectValues(newState, filtersQueryData, isNullCase));
	};

	const getTableDataWithoutPagination = (params, isNullCase = true) => {
		getTableData(mutateSelectValues(params, filtersQueryData, isNullCase));
	};

	const tableData = data || null; // seCachedData(data)

	return [
		withoutPagination ? getTableDataWithoutPagination : getTableDataWithPagination,
		{
			isFetching,
			isLoading,
			error,
			data: tableData,
			pageSize: state?.pagination?.pageSize || pageSize,
			pageIndex: (state?.pagination?.pageIndex || 0) + 1 || 0,
			handlePageChange,
			reset
		}
	];
}

import { memo } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import Permitted from "../Permitted";
import ErrorBoundary from "../ErrorBoundary";
import "./index.css";

function PageActionButton({
	actionId,
	unitId,
	parentUnitId,
	text,
	handleClick,
	title,
	path,
	icon,
	type,
	classname,
	disabled,
	...props
}) {
	return (
		<ErrorBoundary>
			<Permitted actionId={actionId} unitId={unitId} parentUnitId={parentUnitId}>
				<div className={`align-center ${classname}`}>
					{path ? (
						<NavLink to={path}>
							<Button
								onClick={handleClick}
								className="action-button"
								icon={icon}
								type={type}
								title={title}
								disabled={disabled}
								{...props}
							>
								{text}
							</Button>
						</NavLink>
					) : (
						<Button
							onClick={handleClick}
							className="action-button"
							icon={icon}
							type={type}
							title={title}
							disabled={disabled}
							{...props}
						>
							{text}
						</Button>
					)}
				</div>
			</Permitted>
		</ErrorBoundary>
	);
}

PageActionButton.defaultProps = {
	disabled: false,
	actionId: 0,
	unitId: 0,
	parentUnitId: 0,
	text: "",
	handleClick: () => {},
	title: "",
	path: "",
	icon: null,
	type: "primary",
	classname: ""
};
PageActionButton.propTypes = {
	disabled: PropTypes.bool,
	actionId: PropTypes.number,
	unitId: PropTypes.number,
	parentUnitId: PropTypes.number,
	text: PropTypes.string,
	handleClick: PropTypes.func,
	title: PropTypes.string,
	path: PropTypes.string,
	icon: PropTypes.node,
	type: PropTypes.string,
	classname: PropTypes.string
};

export default memo(PageActionButton);

import { memo } from "react";
import PropTypes from "prop-types";
import { Pagination, Table } from "antd";

function TableWithPagination({
	isLoading,
	columns,
	data,
	error,
	totalCount,
	pageSize,
	currentPage,
	setCurrentPage,
	rowKey,
	scroll,
	bordered,
	footer,
	expandable,
	className
}) {
	return (
		<>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				scroll={scroll}
				error={error}
				pagination={false}
				rowKey={rowKey}
				footer={footer || null}
				bordered={bordered}
				expandable={expandable}
				className={className}
			/>
			<div className="flex justify-end align-center mt-16">
				<Pagination
					hideOnSinglePage={data?.length === 0}
					pageSize={pageSize}
					current={currentPage}
					total={totalCount || 0}
					onChange={setCurrentPage}
				/>
			</div>
		</>
	);
}

TableWithPagination.defaultProps = {
	isLoading: false,
	totalCount: 0,
	pageSize: 10,
	currentPage: 0,
	setCurrentPage: () => {},
	columns: [],
	data: [],
	rowKey: "",
	error: {},
	scroll: { x: 1500 },
	bordered: false,
	footer: null,
	expandable: null,
	className: ""
};

TableWithPagination.propTypes = {
	isLoading: PropTypes.bool,
	totalCount: PropTypes.number,
	pageSize: PropTypes.number,
	currentPage: PropTypes.number,
	setCurrentPage: PropTypes.func,
	columns: PropTypes.array,
	data: PropTypes.array,
	rowKey: PropTypes.string,
	error: PropTypes.object,
	scroll: PropTypes.object,
	bordered: PropTypes.bool,
	footer: PropTypes.func,
	expandable: PropTypes.object,
	className: PropTypes.string
};

export default memo(TableWithPagination);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { dataWithPaginationQuery, prepareHeaders } from "utils/helpers";
import { RECIPIENT, RECIPIENT_GET_ALL } from "../constants";

export const recipientProfilersApiSlice = createApi({
	reducerPath: "recipient",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${RECIPIENT}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getRecipientProfilersList: query({
				query: body => {
					return {
						url: RECIPIENT_GET_ALL,
						method: "GET",
						params: dataWithPaginationQuery(body)
					};
				}
			}),
			createRecipientProfiler: mutation({
				query: body => {
					return {
						method: "POST",
						body
					};
				}
			}),
			deleteRecipientProfiler: mutation({
				query: ({ id }) => {
					return {
						url: `${id}`,
						method: "DELETE"
					};
				}
			}),
			updateRecipientProfiler: mutation({
				query: body => {
					const { id } = body;
					delete body.id;
					return {
						url: `${id}`,
						method: "PUT",
						body
					};
				}
			})
		};
	}
});

export const {
	useGetRecipientProfilersListQuery,
	useCreateRecipientProfilerMutation,
	useDeleteRecipientProfilerMutation,
	useUpdateRecipientProfilerMutation
} = recipientProfilersApiSlice;

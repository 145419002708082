import { Button, Space, Switch, Tooltip } from "antd";
import { mockedUnitIds } from "utils/models";
import MultiSelect from "common/MultiSelect";
import ShowMore from "common/ShowMore";
import Permitted from "common/Permitted";
import { EditIcon } from "common/Icons";
import Select from "common/Select";
import Input from "common/Input";
import TreeSelect from "common/TreeSelect";

export const statuses = {
	Upcoming: 0,
	Active: 1,
	Deactivate: 2,
	Expired: 3,
	Finished: 4
};

export const modalTypes = {
	config: "Config",
	activeDeactive: "ActiveDeactive"
};

export const tableColumns = ({ openModal }) => {
	return [
		{
			title: "Chat Name",
			key: "name",
			dataIndex: "name",
			width: 200
		},
		{
			title: "Partner Name",
			key: "partnerNames",
			dataIndex: "partnerNames",
			render: partners => <ShowMore list={partners} />,
			width: 200
		},
		{
			title: "Game Name",
			key: "gameTypeNames",
			dataIndex: "gameTypeNames",
			render: games => <ShowMore list={games} />,
			width: 200
		},
		{
			title: "Status",
			key: "status",
			dataIndex: "status",
			width: 200,
			render: status => (status === 1 ? "Active" : "Inactive")
		},
		{
			title: "Actions",
			key: "status",
			dataIndex: "status",
			align: "right",
			fixed: "right",
			render: (status, data) => {
				return (
					<Permitted unitId={mockedUnitIds.ChatPlayers} parentUnitId={mockedUnitIds.Chat} actionId={2}>
						<Space size={16} className="align-center justify-end">
							<Button
								onClick={() => openModal(modalTypes.config, { ...data, isEdit: true })}
								shape="circle"
								className="mr-8 muted-text"
								type="link"
								icon={<EditIcon />}
							/>
							<Tooltip title={status === 1 ? "Deactivate" : "Activate"}>
								<Switch
									checked={status === 1}
									onChange={() => openModal(modalTypes.activeDeactive, { ...data, type: modalTypes.activeDeactive })}
									className="flex"
									size="small"
									style={{ width: 34 }}
								/>
							</Tooltip>
						</Space>
					</Permitted>
				);
			},
			width: 150
		}
	];
};

export const modalFilters = [
	{
		Component: Input,
		name: "chatName",
		label: "Chat Name",
		isRequired: true,
		help: "Please input chat name!",
		componentProps: {
			placeholder: "Type"
		}
	},
	{
		Component: TreeSelect,
		name: "partners",
		label: "Partner",
		isRequired: true,
		help: "Please select Partner(s)!",
		componentProps: {
			placeholder: "Select Partner(s)",
			withSearch: true
		}
	},
	{
		Component: MultiSelect,
		name: "gameTypes",
		label: "Games",
		isRequired: true,
		help: "Please select Game(s)!",
		componentProps: {
			placeholder: "Select Game(s)",
			withSearch: true
		}
	}
];

export const tableFilters = [
	{
		Component: MultiSelect,
		name: "chatNames",
		label: "Chat name",
		componentProps: {
			placeholder: "Select Chat(s)",
			withSearch: true
		}
	},
	{
		Component: MultiSelect,
		name: "partners",
		label: "Partner",
		componentProps: {
			placeholder: "Select Partner(s)",
			withSearch: true
		}
	},
	{
		Component: MultiSelect,
		name: "gameTypes",
		label: "Games",
		componentProps: {
			placeholder: "Select Game(s)",
			withSearch: true
		}
	},
	{
		Component: Select,
		help: "Please select Status!",
		name: "statuses",
		label: "Status",
		componentProps: {
			placeholder: "Select Status",
			withSearch: true
		}
	}
];

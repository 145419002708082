import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "utils/helpers";
import {
	CreateUpdateMoneyLand,
	GetCreatePromotionFilters,
	GetPromotionsFilters,
	MoneyLand,
	MoneyLandGetList,
	GetPlayersFilters,
	PlayersGetList,
	EditMoneyLandStatus
} from "../constants";

export const moneyLandSlice = createApi({
	reducerPath: "moneyLand",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${MoneyLand}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getPromotionsFilters: query({
				query: () => GetPromotionsFilters
			}),
			moneyLandGetList: mutation({
				query: body => ({
					url: MoneyLandGetList,
					method: "POST",
					body
				})
			}),
			getCreatePromotionFilters: query({
				query: () => GetCreatePromotionFilters
			}),
			createUpdateMoneyLand: mutation({
				query: body => ({
					url: CreateUpdateMoneyLand,
					method: "POST",
					body
				})
			}),
			getCampaign: query({
				query: id => `${id}`
			}),
			getPlayersFilters: query({
				query: () => GetPlayersFilters
			}),
			playersGetList: mutation({
				query: body => ({
					url: PlayersGetList,
					method: "POST",
					body
				})
			}),
			editMoneyLandStatus: mutation({
				query: body => ({
					url: EditMoneyLandStatus,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useGetPromotionsFiltersQuery,
	useMoneyLandGetListMutation,
	useGetCreatePromotionFiltersQuery,
	useCreateUpdateMoneyLandMutation,
	useGetCampaignQuery,
	useGetPlayersFiltersQuery,
	usePlayersGetListMutation,
	useEditMoneyLandStatusMutation
} = moneyLandSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "utils/helpers";

import {
	COMPARE_STATISTICS,
	DASHBOARD,
	GET_BAR_CHART,
	GET_GAME_REPORT_WITH_PARTNERS,
	GET_TOP_GAMES_PIE_CHART,
	GET_TOP_PARTNERS_PIE_CHART
} from "../constants";

export const cachedApiSlice = createApi({
	reducerPath: "cached",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}`,
		prepareHeaders
	}),
	keepUnusedDataFor: 28800,
	endpoints({ query }) {
		return {
			getGameReportWithPartners: query({
				query: body => {
					const clone = { ...body };
					const partnerIds = clone.partners;
					const gameIds = clone.gameTypes;
					if (clone.frequency === 0) {
						clone.frequency = 1;
					}
					delete clone.partners;
					delete clone.gameTypes;
					return {
						url: `${DASHBOARD}/${GET_GAME_REPORT_WITH_PARTNERS}`,
						method: "POST",
						body: {
							...clone,
							partnerIds,
							gameIds
						}
					};
				},
				transformResponse: (resp, meta, arg) => {
					const tableMainData = [];
					const totalRow = {
						name: "Total",
						key: "total",
						data: {}
					};

					if (!resp.tableData && !resp.totalReportByGameType) {
						return [];
					}

					const dates = Object.keys(resp.tableData || {});

					dates?.forEach(date => {
						const Games = Object.keys(resp.tableData[date].report);
						Games.forEach(game => {
							totalRow.data[date] = {
								dynamicsPercentage: resp.tableData[date].dynamicsPercentage,
								betAmount: resp.tableData[date].betAmount,
								ggr: resp.tableData[date].ggr,
								betCount: resp.tableData[date].betCount,
								uniquePlayersCount: resp.tableData[date].uniquePlayersCount,
								margin: resp.tableData[date].margin
							};

							const tableDataIndex = tableMainData.findIndex(
								item => item.name === resp.tableData[date].report[game].name
							);

							if (tableDataIndex === -1) {
								tableMainData.push({
									id: resp.tableData[date].report[game].id,
									name: resp.tableData[date].report[game].name,
									key: game + resp.tableData[date].report[game].name,
									data: {
										[date]: {
											betAmount: resp.tableData[date].report[game].betAmount,
											ggr: resp.tableData[date].report[game].ggr,
											betCount: resp.tableData[date].report[game].betCount,
											uniquePlayersCount: resp.tableData[date].report[game].uniquePlayersCount,
											margin: resp.tableData[date].report[game].margin,
											dynamicsPercentage: resp.tableData[date].report[game].dynamicsPercentage
										},
										totalColumn: {
											betAmount: resp.totalReportByGameType[game]?.betAmount,
											ggr: resp.totalReportByGameType[game]?.ggr,
											betCount: resp.totalReportByGameType[game]?.betCount,
											uniquePlayersCount: resp.totalReportByGameType[game]?.uniquePlayersCount,
											margin: resp.totalReportByGameType[game]?.margin
										}
									},
									children: arg?.withChilds
										? resp?.tableData[date]?.report?.[game]?.child?.map((partner, i) => {
												return {
													id: partner.id,
													name: partner.name,
													key: game + i + partner.id + resp.tableData[date].report[game].name,
													data: {
														[date]: {
															betAmount: partner.betAmount,
															child: partner.child,
															betCount: partner.betCount,
															ggr: partner.ggr,
															uniquePlayersCount: partner.uniquePlayersCount,
															margin: partner.margin,
															dynamicsPercentage: partner.dynamicsPercentage
														},
														totalColumn: {
															betAmount: resp.totalReportByGameType[game].children[partner.id].betAmount,
															ggr: resp.totalReportByGameType[game].children[partner.id].ggr,
															betCount: resp.totalReportByGameType[game].children[partner.id].betCount,
															uniquePlayersCount:
																resp.totalReportByGameType[game].children[partner.id].uniquePlayersCount,
															margin: resp.totalReportByGameType[game].children[partner.id].margin
														}
													}
												};
										  }) || []
										: null
								});
							} else {
								tableMainData[tableDataIndex].data[date] = {
									dynamicsPercentage: resp.tableData[date].report[game].dynamicsPercentage,
									betAmount: resp.tableData[date].report[game].betAmount,
									ggr: resp.tableData[date].report[game].ggr,
									betCount: resp.tableData[date].report[game].betCount,
									margin: resp.tableData[date].report[game].margin,
									uniquePlayersCount: resp.tableData[date].report[game].uniquePlayersCount
								};
								resp.tableData[date].report[game]?.child?.forEach((partner, i) => {
									const findPartnerIndex = tableMainData[tableDataIndex]?.children?.findIndex(
										item => item.id === partner.id
									);
									if (findPartnerIndex !== -1 && tableMainData[tableDataIndex].children) {
										tableMainData[tableDataIndex].children[findPartnerIndex].data[date] = {
											betAmount: partner.betAmount,
											child: partner.child,
											betCount: partner.betCount,
											ggr: partner.ggr,
											uniquePlayersCount: partner.uniquePlayersCount,
											margin: partner.margin,
											dynamicsPercentage: partner.dynamicsPercentage
										};
									} else {
										tableMainData[tableDataIndex].children.push({
											id: partner.id,
											name: partner.name,
											key: game + i + partner.id + resp.tableData[date].report[game].name,
											data: {
												[date]: {
													betAmount: partner.betAmount,
													child: partner.child,
													betCount: partner.betCount,
													ggr: partner.ggr,
													uniquePlayersCount: partner.uniquePlayersCount,
													margin: partner.margin,
													dynamicsPercentage: partner.dynamicsPercentage
												},
												totalColumn: {
													betAmount: resp.totalReportByGameType[game].children[partner.id].betAmount,
													ggr: resp.totalReportByGameType[game].children[partner.id].ggr,
													betCount: resp.totalReportByGameType[game].children[partner.id].betCount,
													uniquePlayersCount: resp.totalReportByGameType[game].children[partner.id].uniquePlayersCount,
													margin: resp.totalReportByGameType[game].children[partner.id].margin
												}
											}
										});
									}
								});

								tableMainData[tableDataIndex].child?.map((partner, i) => ({
									id: partner.id,
									name: partner.name,
									key: game + i,
									data: {
										[date]: {
											betAmount: partner.betAmount,
											child: partner.child,
											betCount: partner.betCount,
											ggr: partner.ggr,
											uniquePlayersCount: partner.uniquePlayersCount,
											margin: partner.margin,
											dynamicsPercentage: partner.dynamicsPercentage
										}
									}
								}));
							}
						});
					});

					totalRow.data.totalColumn = {
						dynamicsPercentage: resp.totalReportByGameType.Totals?.dynamicsPercentage,
						betAmount: resp.totalReportByGameType.Totals?.betAmount,
						ggr: resp.totalReportByGameType.Totals?.ggr,
						betCount: resp.totalReportByGameType.Totals?.betCount,
						uniquePlayersCount: resp.totalReportByGameType.Totals?.uniquePlayersCount,
						margin: resp.totalReportByGameType.Totals?.margin
					};
					tableMainData.push(totalRow);

					return tableMainData;
				}
			}),
			compareStatistics: query({
				query: body => ({
					url: `${DASHBOARD}/${COMPARE_STATISTICS}`,
					method: "POST",
					body
				})
			}),
			getTopGamesPieChart: query({
				query: body => ({
					url: `${DASHBOARD}/${GET_TOP_GAMES_PIE_CHART}`,
					method: "POST",
					body
				})
			}),
			getTopPartnersPieChart: query({
				query: body => ({
					url: `${DASHBOARD}/${GET_TOP_PARTNERS_PIE_CHART}`,
					method: "POST",
					body
				})
			}),
			getBarChart: query({
				query: body => ({
					url: `${DASHBOARD}/${GET_BAR_CHART}`,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useCompareStatisticsQuery,
	useGetGameReportWithPartnersQuery,
	useGetTopGamesPieChartQuery,
	useGetTopPartnersPieChartQuery,
	useGetBarChartQuery
} = cachedApiSlice;

import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { strApply, strReset } from "./resources.json";

function HeaderFilters({
	withSubmit,
	withReset,
	filtersQueryData,
	filtersComponentData,
	handleReset,
	handleApply,
	selectedFiltersGroup,
	setSelectedFiltersGroup,
	handleChange,
	readonly,
	classname,
	children,
	defaultFilters,
	disabledApplay,
	updateValidation,
	additionalButton
}) {
	const [form] = useForm();

	useEffect(() => {
		form.setFieldsValue(selectedFiltersGroup);
	}, [selectedFiltersGroup]);

	const isInvalidForm = useMemo(() => {
		return !filtersComponentData?.some(({ name, componentProps: { secondaryName } }) => {
			if (defaultFilters[name] !== undefined) {
				return false;
			}
			if (name === "rangePicker") {
				return selectedFiltersGroup.startDate && selectedFiltersGroup.endDate;
			}
			if (selectedFiltersGroup[name] && !Array.isArray(selectedFiltersGroup[name])) {
				return selectedFiltersGroup[name];
			}

			if (secondaryName && selectedFiltersGroup[secondaryName]) {
				return selectedFiltersGroup[secondaryName];
			}

			if (selectedFiltersGroup[name]?.length) {
				return selectedFiltersGroup[name];
			}
			return false;
		});
	}, [selectedFiltersGroup, handleApply, handleChange, filtersComponentData]);

	const onFinish = () => {
		handleApply();
	};

	useEffect(() => {
		if (updateValidation) {
			form.submit();
		}
	}, [updateValidation]);

	return (
		<div className={`flex flex-between ${classname}`}>
			<Form className="form-main" layout="vertical" form={form} disabled={readonly} onFinish={onFinish}>
				{filtersComponentData.map(
					({ name, label, Component, componentProps, isRequired, help, formItemClassName, rules = [], dataType }) => {
						const data = selectedFiltersGroup[name];
						const duoSelectData = selectedFiltersGroup[componentProps?.secondaryName];

						const startDate = componentProps.customKey
							? selectedFiltersGroup[`${componentProps.customKey}StartDate`]
							: selectedFiltersGroup.startDate;
						const endDate = componentProps.customKey
							? selectedFiltersGroup[`${componentProps.customKey}EndDate`]
							: selectedFiltersGroup.endDate;

						const checked = typeof data === "boolean" ? { checked: data } : {};

						const rangePickerDate = startDate || endDate ? { rangePickerDate: [startDate, endDate] } : {};
						return (
							<Form.Item
								name={name}
								key={name}
								label={label}
								rules={[
									{
										required: isRequired,
										message: help
									},
									...rules
								]}
								className={formItemClassName}
							>
								<Component
									{...componentProps}
									{...(dataType === "duoselect" ? { duoSelectData, setSelectedFiltersGroup } : {})}
									{...rangePickerDate}
									name={name}
									className="test"
									selectedFiltersGroup={selectedFiltersGroup}
									options={filtersQueryData ? filtersQueryData[name] : null}
									onChange={handleChange}
									data={data}
									form={form}
									isRequired={isRequired}
									{...checked}
								/>
							</Form.Item>
						);
					}
				)}
				{withReset || withSubmit ? (
					<div className="align-center ml-auto">
						{withReset ? (
							<Form.Item
								className="filter-buttons-form"
								onClick={() => {
									handleReset();
									form.resetFields();
								}}
							>
								<div className="filter-buttons flex-between">
									<Button type="link">{strReset}</Button>
								</div>
							</Form.Item>
						) : null}
						{withSubmit ? (
							<Form.Item className="filter-buttons-form ml-16">
								<div className="filter-buttons flex-between">
									<Button type="primary" disabled={isInvalidForm || disabledApplay} htmlType="submit">
										{strApply}
									</Button>
								</div>
							</Form.Item>
						) : null}
						{additionalButton && <Form.Item className="filter-buttons-form ml-16">{additionalButton}</Form.Item>}
					</div>
				) : null}
			</Form>
			{children && <div className="self-bottom mb-24 ml-16">{children}</div>}
		</div>
	);
}

HeaderFilters.propTypes = {
	updateValidation: PropTypes.bool,
	withSubmit: PropTypes.bool,
	disabledApplay: PropTypes.bool,
	withReset: PropTypes.bool,
	filtersQueryData: PropTypes.object,
	filtersComponentData: PropTypes.array,
	handleApply: PropTypes.func,
	handleReset: PropTypes.func,
	selectedFiltersGroup: PropTypes.object,
	setSelectedFiltersGroup: PropTypes.func,
	readonly: PropTypes.bool,
	classname: PropTypes.string,
	children: PropTypes.element,
	handleChange: PropTypes.func,
	defaultFilters: PropTypes.object,
	additionalButton: PropTypes.node
};
HeaderFilters.defaultProps = {
	updateValidation: false,
	withSubmit: false,
	disabledApplay: false,
	withReset: false,
	filtersQueryData: {},
	filtersComponentData: [],
	handleApply: () => {},
	handleReset: () => {},
	handleChange: () => {},
	selectedFiltersGroup: {},
	setSelectedFiltersGroup: () => {},
	readonly: false,
	classname: "",
	children: null,
	defaultFilters: {},
	additionalButton: null
};

export default HeaderFilters;

import { Button, Space } from "antd";
import { EditIcon, ViewIcon } from "common/Icons";
import Permitted from "common/Permitted";
import Select from "common/Select";
import { mockedUnitIds } from "utils/models";

export const mockData = [
	{
		id: 390,
		partnerName: "Deactive-test"
	}
];

export const modalName = "modalName";

export const tableColumns = openModal => [
	{
		title: "Partner Name",
		key: "partnerName",
		dataIndex: "partnerName",
		width: 200
	},
	{
		title: "Actions",
		key: "status",
		align: "right",
		fixed: "right",
		render: (status, data) => {
			return (
				<Permitted unitId={mockedUnitIds.ChatContent} parentUnitId={mockedUnitIds.Chat} actionId={2}>
					<Space size={16} className="align-center justify-end">
						<Button
							onClick={() => openModal(modalName, { ...data, modalType: "view" })}
							shape="circle"
							className="mr-8 muted-text"
							type="link"
							icon={<ViewIcon />}
						/>
						<Button
							onClick={() => openModal(modalName, { ...data, modalType: "edit" })}
							shape="circle"
							className="mr-8 muted-text"
							type="link"
							icon={<EditIcon />}
						/>
					</Space>
				</Permitted>
			);
		},
		width: 150
	}
];

export const tableFilters = [
	{
		Component: Select,
		name: "partners",
		label: "Partner",
		isRequired: true,
		help: "Please select Partner!",
		componentProps: {
			placeholder: "Select Partner",
			anotherRow: { id: "default", name: "Default" },
			withSearch: true
		}
	}
];

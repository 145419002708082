import { createContext, useReducer } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext({});

const initialState = {
	user: {},
	storageChanged: false
};

const reducer = (state, action) => {
	const { payload, type } = action;
	switch (type) {
		case "SIGN_IN":
			return {
				user: payload
			};
		case "SIGN_OUT":
			return {
				user: {}
			};
		case "SAVE_PERMISSIONS":
			return {
				...state,
				appPermissions: payload
			};
		default:
			throw new Error();
	}
};

function AuthProvider({ children }) {
	const reducerState = useReducer(reducer, initialState);

	return <AuthContext.Provider value={reducerState}>{children}</AuthContext.Provider>;
}

AuthProvider.defaultProps = {
	children: {}
};

AuthProvider.propTypes = {
	children: PropTypes.object
};

export { AuthContext, AuthProvider };

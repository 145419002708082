import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	CREATE_SKINNING,
	EDIT_SKINNING,
	EDIT_SKINNING_STATUS,
	GET_SKINNING_BY_ID,
	GET_SKINNINGS_FILTERS,
	GET_SKINNINGS_LIST,
	SKINNING
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const gameSkinningApiSlice = createApi({
	reducerPath: "gameSkinning",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${SKINNING}`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getSkinningsFilters: query({
				query: () => GET_SKINNINGS_FILTERS
			}),
			getSkinningsList: mutation({
				query: body => ({
					url: GET_SKINNINGS_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			createSkinning: mutation({
				query: body => ({
					url: CREATE_SKINNING,
					method: "POST",
					body: body
				})
			}),
			editSkinning: mutation({
				query: body => ({
					url: EDIT_SKINNING,
					method: "POST",
					body: body
				})
			}),
			editSkinningStatus: mutation({
				query: body => ({
					url: EDIT_SKINNING_STATUS,
					method: "POST",
					body: body
				})
			}),
			getSkinningById: mutation({
				query: body => {
					return {
						url: GET_SKINNING_BY_ID,
						method: "POST",
						params: body
					};
				}
			})
		};
	}
});

export const {
	useGetSkinningsFiltersQuery,
	useGetSkinningsListMutation,
	useCreateSkinningMutation,
	useEditSkinningMutation,
	useEditSkinningStatusMutation,
	useGetSkinningByIdMutation
} = gameSkinningApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	GET_PARTNER_CONFIGURATION_CREATE_FILTERS,
	GET_PARTNER_CONFIGURATION_LIST_FILTERS,
	PARTNER_CONFIGURATION,
	PARTNER_CONFIGURATION_GET_LIST,
	PARTNER_CONFIGURATION_CREATE
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const playLinkApiSlice = createApi({
	reducerPath: "playLink",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${PARTNER_CONFIGURATION}`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getPartnerConfigurationFilters: query({
				query: () => GET_PARTNER_CONFIGURATION_LIST_FILTERS
			}),
			getPartnerConfigurationCreateFilters: query({
				query: () => GET_PARTNER_CONFIGURATION_CREATE_FILTERS
			}),
			getPartnerConfigurationList: mutation({
				query: body => ({
					url: PARTNER_CONFIGURATION_GET_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			createPartnerConfiguration: mutation({
				query: body => {
					return {
						url: "",
						method: "POST",
						body
					};
				}
			}),
			getPartnerConfigurationById: query({
				query: ({ id }) => {
					return {
						url: `${id}`
					};
				}
			}),
			changePartnerConfigurationById: mutation({
				query: ({ id, body }) => {
					return {
						url: `${id}`,
						method: "PUT",
						body
					};
				}
			}),
			deletePartnerConfigurationById: mutation({
				query: id => {
					return {
						url: `${id}`,
						method: "DELETE"
					};
				}
			})
		};
	}
});

export const {
	useGetPartnerConfigurationFiltersQuery,
	useGetPartnerConfigurationListMutation,
	useGetPartnerConfigurationCreateFiltersQuery,
	useGetPartnerConfigurationByIdQuery,
	useChangePartnerConfigurationByIdMutation,
	useDeletePartnerConfigurationByIdMutation,
	useCreatePartnerConfigurationMutation
} = playLinkApiSlice;

import Input from "common/Input";
import Profanity from "./components/Profanity";

export const modalTypes = {
	add: "Add",
	import: "Import"
};

export const tableColumns = (updateProfanity, updateLoading) => [
	{
		title: "Profanity",
		key: "word",
		dataIndex: "word",
		width: 200,
		render: (word, { id }) => (
			<Profanity name={word} id={id} updateProfanity={updateProfanity} isLoading={updateLoading} />
		)
	}
];

export const tableFilters = [
	{
		Component: Input,
		name: "search",
		label: "Search ",
		componentProps: {
			placeholder: "Type"
		}
	}
];

import { useCallback, useState } from "react";

export function useModal() {
	const [modals, setModals] = useState({});

	const open = useCallback((key, data = {}) => {
		setModals(prev => ({
			...prev,
			[key]: {
				...data,
				open: true
			}
		}));
	}, []);

	const close = useCallback(key => {
		setModals(prev => ({
			...prev,
			[key]: { open: false }
		}));
	}, []);

	return [modals, open, close];
}

import { Tabs } from "antd";
import Avatars from "./Avatars";
import Stickers from "./Stickers";

const cashbackContent = [
	{
		label: "Stickers",
		key: 1,
		children: <Stickers />
	},
	{
		label: "Avatars",
		key: 2,
		children: <Avatars />
	}
];

function ChatContent() {
	return <Tabs defaultActiveKey={1} items={cashbackContent} className="my-antd-tabs" />;
}

export default ChatContent;

import { useRef, memo } from "react";
import { Input } from "antd";
import PropTypes from "prop-types";

function CustomInput({
	onChange,
	name,
	data,
	placeholder,
	inputType,
	contentEditable,
	allowClear,
	status,
	disabled,
	form,
	...props
}) {
	const ref = useRef();
	const handleChange = e => {
		onChange({ value: e, name }, "input", form);
	};

	return (
		<Input
			{...props}
			status={status}
			disabled={disabled}
			ref={ref}
			type={inputType}
			onChange={handleChange}
			value={data}
			placeholder={placeholder}
			contentEditable={contentEditable}
			allowClear={allowClear}
		/>
	);
}

export default memo(CustomInput);

CustomInput.defaultProps = {
	onChange: () => {},
	data: "",
	inputType: "",
	placeholder: "",
	contentEditable: false,
	allowClear: true,
	disabled: false,
	status: "",
	form: {}
};

CustomInput.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string.isRequired,
	inputType: PropTypes.string,
	data: PropTypes.string,
	placeholder: PropTypes.string,
	contentEditable: PropTypes.bool,
	allowClear: PropTypes.bool,
	disabled: PropTypes.bool,
	status: PropTypes.string,
	form: PropTypes.object
};

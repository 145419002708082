import { useEffect, useRef } from "react";
import { Layout } from "antd";
import ErrorBoundary from "common/ErrorBoundary";
import useWindowVisibility from "hooks/useWindowVisibility";
import { useSetLastActivityMutation } from "app/services/accountApiSlice";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import "./index.css";

const { Header } = Layout;

function HeaderWrapper() {
	const isFirstLoading = useRef(true);
	const [setLastActivity] = useSetLastActivityMutation();
	const windowIsVisible = useWindowVisibility();

	useEffect(() => {
		if (windowIsVisible) {
			if (!isFirstLoading.current) {
				setLastActivity();
			}
			isFirstLoading.current = false;
		}
	}, [windowIsVisible]);

	return (
		<ErrorBoundary>
			<Header className="header-main">
				<HeaderLeft />
				<HeaderRight />
			</Header>
		</ErrorBoundary>
	);
}

export default HeaderWrapper;

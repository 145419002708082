import { createSlice } from "@reduxjs/toolkit";

const initialState = { partnersCurrencies: {} };

export const currenciesSlice = createSlice({
	name: "currencies",
	initialState,
	reducers: {
		updatePartnersCurrencies: (state, { payload }) => {
			state.partnersCurrencies = payload;
		}
	}
});

export const selectPartnersCurrencies = state => state.currencies.partnersCurrencies;
export const { updatePartnersCurrencies } = currenciesSlice.actions;

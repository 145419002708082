import { Modal } from "antd";
import PropTypes from "prop-types";

const CustomModal = ({
	open,
	title,
	onOk,
	onCancel,
	okText,
	okButtonLoading,
	centered,
	bodyStyle,
	body,
	modalWidth,
	...props
}) => {
	return (
		<Modal
			open={open}
			title={title}
			onOk={okButtonLoading ? null : onOk}
			onCancel={okButtonLoading ? null : onCancel}
			okText={okText}
			okButtonProps={{ loading: okButtonLoading }}
			centered={centered}
			bodyStyle={bodyStyle}
			width={modalWidth}
			{...props}
		>
			{body}
		</Modal>
	);
};

CustomModal.defaultProps = {
	open: false,
	title: "",
	okText: "",
	okButtonLoading: false,
	centered: true,
	onOk: () => {},
	onCancel: () => {},
	bodyStyle: {},
	body: "",
	modalWidth: 344
};

CustomModal.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	okText: PropTypes.string,
	okButtonLoading: PropTypes.bool,
	centered: PropTypes.bool,
	bodyStyle: PropTypes.object,
	onOk: PropTypes.func,
	onCancel: PropTypes.func,
	body: PropTypes.node,
	modalWidth: PropTypes.number
};

export default CustomModal;

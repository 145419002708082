import { Space } from "antd";
import MultiSelect from "common/MultiSelect";
import NumberInput from "common/NumberInput";
import Select from "common/Select";
import moment from "moment";

export const currencyFilters = disabled => [
	{
		Component: Select,
		name: "partners",
		label: "Partner",
		isRequired: true,
		formItemClassName: "medium",
		help: "Select Partner!",
		componentProps: {
			placeholder: "Select Partner",
			withSearch: true
		}
	},
	{
		Component: Select,
		name: "currencies",
		label: "Currency",
		isRequired: true,
		formItemClassName: "small",
		help: "Select Currency!",
		componentProps: {
			placeholder: "Select Currency",
			withSearch: true,
			disabled
		}
	},
	{
		Component: Select,
		name: "dateRange",
		label: "Date/Time Range",
		isRequired: true,
		formItemClassName: "medium",
		help: "Select Date/Time !",
		componentProps: {
			placeholder: "Select Date/Time Range",
			withSearch: true,
			disabled
		}
	}
];

export const precisionFilters = disabled => [
	{
		Component: Select,
		name: "partners",
		label: "Partner",
		isRequired: true,
		help: "Select Partner!",
		componentProps: {
			placeholder: "Select Partner",
			withSearch: true
		}
	},
	{
		Component: MultiSelect,
		name: "currencies",
		label: "Currency",
		isRequired: true,
		help: "Select Currency!",
		componentProps: {
			placeholder: "Select Currency",
			withSearch: true,
			maxTagCount: 4,
			disabled
		}
	}
];

export const currencyColumns = ({ currencyName, isEdit, isRangeEdit, changeCoinRate, changedRates, errors }) => [
	{
		key: "1",
		title: "Date",
		dataIndex: "fromDate",
		align: "center"
	},
	{
		key: "2",
		title: "Exchange Rate",
		dataIndex: "coinRate",
		align: "center",
		render: function PartnerSiteId(coinRate, rate) {
			return (
				<Space size={16} className="flex align-center">
					<NumberInput
						onChange={isEdit && !isRangeEdit ? changeCoinRate : null}
						value={changedRates[rate.id] === undefined ? coinRate : changedRates[rate.id]}
						bordered={isEdit && !isRangeEdit}
						readOnly={!isEdit || isRangeEdit}
						precisionLength={10}
						className="text-right"
						name={rate.id}
						status={errors[rate.id]}
					/>
					<span>{currencyName} = 1 EURO</span>
				</Space>
			);
		}
	}
];

export const precisionColumns = ({ isEdit, currencies, changeCoinRate, changedPrecisions }) => [
	{
		key: "1",
		title: "Currency",
		dataIndex: "id",
		align: "center",
		render: id => currencies?.find(item => item.id === id)?.name
	},
	{
		key: "2",
		title: "Precision",
		dataIndex: "precision",
		align: "center",
		render: function PartnerSiteId(precision, { id }) {
			return (
				<Space size={16} className="flex justify-center">
					<NumberInput
						onChange={isEdit ? changeCoinRate : null}
						value={changedPrecisions[id] === undefined ? precision : changedPrecisions[id]}
						bordered={isEdit}
						readOnly={!isEdit}
						maxNumber={9}
						name={id}
						className={isEdit ? "" : "text-center"}
					/>
				</Space>
			);
		}
	}
];

export const getDateForShowing = filterData => {
	let dateStr = "";
	switch (filterData.dateRange) {
		case "5": {
			dateStr = `${moment(filterData.startDate).format("DD/MM/YYYY")} - ${moment(filterData.endDate).format(
				"DD/MM/YYYY"
			)}`;
			break;
		}
		case "4": {
			const lastYear = moment().subtract(1, "year");
			dateStr = `${moment(lastYear).format("DD/MM/YYYY")} - ${moment(new Date()).format("DD/MM/YYYY")}`;
			break;
		}
		case "3": {
			const lastMonth = moment().subtract(1, "month");
			dateStr = `${moment(lastMonth).format("DD/MM/YYYY")} - ${moment(new Date()).format("DD/MM/YYYY")}`;
			break;
		}
		case "2": {
			const lastWeek = moment().subtract(1, "week");
			dateStr = `${moment(lastWeek).format("DD/MM/YYYY")} - ${moment(new Date()).format("DD/MM/YYYY")}`;
			break;
		}
		case "1": {
			const lastDay = moment().subtract(1, "day");
			dateStr = `${moment(lastDay).format("DD/MM/YYYY")} - ${moment(new Date()).format("DD/MM/YYYY")}`;
			break;
		}
	}

	return dateStr;
};

export const isInvalidRangeRate = num => {
	if (+num <= 0) {
		return "'Rate' must be greater than '0'";
	}
	const [abs, decimal] = num.split(".");
	if (decimal?.length > 10) {
		return "A maximum of 10 digits allowed before after the decimal.";
	}
	if (abs.length > 8) {
		return "A maximum of 8 digits are allowed before.";
	}
};

import PropTypes from "prop-types";
import "./index.less";

export default function Fieldset({ children, title, className, icon, errorMsg }) {
	return (
		<>
			<div className={`fieldset ${className}`}>
				<h4 className="title">
					{title} {icon || null}
				</h4>
				{children}
			</div>
			{errorMsg ? <span className="error-text">{errorMsg}</span> : null}
		</>
	);
}

Fieldset.defaultProps = {
	icon: null,
	children: "",
	title: "",
	className: "",
	errorMsg: ""
};

Fieldset.propTypes = {
	children: PropTypes.node,
	icon: PropTypes.node,
	title: PropTypes.string,
	className: PropTypes.string,
	errorMsg: PropTypes.string
};

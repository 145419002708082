import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	DRAW,
	GET_DRAW_TICKETS,
	GET_DETAILS_VIEW,
	GET_TICKETS_LIST,
	GET_TIKETS_LIST_FILTER,
	GET_DRAWS_LIST_FILTER,
	GET_DRAWS,
	GET_GAME_INFO,
	GET_PLAYER_LOG_FILTERS,
	GET_PLAYERS_LOG,
	RESEND_BET,
	RESEND_BETS,
	CHECK_BET_STATUS
} from "app/constants";
import { dataWithPagination, dataWithPaginationQuery, prepareHeaders } from "utils/helpers";

export const drawApiSlice = createApi({
	reducerPath: "draw",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${DRAW}/`,
		prepareHeaders
	}),
	endpoints({ query, mutation }) {
		return {
			getTicketsListFilter: query({
				query: () => GET_TIKETS_LIST_FILTER
			}),
			getDrawsListFilter: query({
				query: () => GET_DRAWS_LIST_FILTER
			}),
			getDrawTickets: query({
				query: body => ({
					url: GET_DRAW_TICKETS,
					params: dataWithPaginationQuery(body)
				})
			}),
			getDetailsView: mutation({
				query: body => ({
					url: GET_DETAILS_VIEW,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getDraws: query({
				query: body => ({
					url: GET_DRAWS,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getTicketsList: query({
				query: body => ({
					url: GET_TICKETS_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getGameInfo: query({
				query: body => ({
					url: GET_GAME_INFO,
					params: body
				})
			}),
			getPlayerLogFilters: query({
				query: () => GET_PLAYER_LOG_FILTERS
			}),
			getPlayerLogs: mutation({
				query: body => ({
					url: GET_PLAYERS_LOG,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			resendBet: mutation({
				query: body => ({
					url: RESEND_BET,
					body,
					method: "POST"
				})
			}),
			resendBets: mutation({
				query: body => ({
					url: RESEND_BETS,
					method: "POST",
					body
				})
			}),
			checkBetStatus: mutation({
				query: body => ({
					url: CHECK_BET_STATUS,
					body,
					method: "POST"
				})
			})
		};
	}
});

export const {
	useGetTicketsListFilterQuery,
	useGetDrawsListFilterQuery,
	useGetDrawTicketsQuery,
	useGetDetailsViewMutation,
	useGetDrawsQuery,
	useGetTicketsListQuery,
	useGetGameInfoQuery,
	useGetPlayerLogFiltersQuery,
	useGetPlayerLogsMutation,
	useResendBetMutation,
	useResendBetsMutation,
	useCheckBetStatusMutation
} = drawApiSlice;

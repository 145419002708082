import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "utils/helpers";
import {
	STORAGE,
	GET_FILES,
	CREATE_FOLDER,
	DELETE_FOLDER,
	DELETE_FILE,
	UPLOAD_FILE,
	IMPORT_FILES,
	GET_FILE,
	DOWNLOAD_FILE,
	EXPORT_FILES,
	RENAME_FILE,
	RENAME_FOLDER,
	REPLACE_FILES
} from "../constants";

export const storageManagementApiSlice = createApi({
	reducerPath: "storageManagement",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${STORAGE}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ mutation }) {
		return {
			getFile: mutation({
				query: (path = "") => ({
					url: `${GET_FILE}?path=${path}`
				})
			}),
			getFiles: mutation({
				query: (path = "") => ({
					url: `${GET_FILES}?path=${path}`
				})
			}),
			createFolder: mutation({
				query: (path = "") => ({
					url: `${CREATE_FOLDER}?folderFullPath=${path}`,
					method: "POST"
				})
			}),
			deleteFolder: mutation({
				query: (path = "") => ({
					url: `${DELETE_FOLDER}?folderFullPath=${path}`,
					method: "DELETE"
				})
			}),
			deleteFile: mutation({
				query: (path = "") => ({
					url: `${DELETE_FILE}?path=${path}`,
					method: "DELETE"
				})
			}),
			uploadFile: mutation({
				query: ({ path, file }) => {
					return {
						url: `${UPLOAD_FILE}?fullPath=${path}`,
						body: file,
						method: "POST"
					};
				}
			}),
			importFiles: mutation({
				query: ({ path, file }) => {
					return {
						url: `${IMPORT_FILES}?fullPath=${path}`,
						body: file,
						method: "POST"
					};
				}
			}),
			downloadFile: mutation({
				query: path => {
					return {
						responseHandler: response => response.blob(),
						url: `${DOWNLOAD_FILE}?path=${path}`,
						method: "POST"
					};
				}
			}),
			exportFiles: mutation({
				query: ({ path, fileName }) => {
					return {
						responseHandler: response => response.blob(),
						url: `${EXPORT_FILES}?path=${path}&fileName=${fileName}`,
						method: "POST"
					};
				}
			}),
			renameFile: mutation({
				query: ({ newName, fileFullPath }) => {
					return {
						url: `${RENAME_FILE}`,
						body: { newName, fileFullPath },
						method: "PUT"
					};
				}
			}),
			renameFolder: mutation({
				query: ({ newName, fileFullPath }) => {
					return {
						url: `${RENAME_FOLDER}`,
						body: { newName, fileFullPath },
						method: "PUT"
					};
				}
			}),
			replaceFiles: mutation({
				query: ({ needToBeReplacedObjects, notNeedToBeReplacedObjects }) => {
					return {
						url: `${REPLACE_FILES}`,
						body: { needToBeReplacedObjects, notNeedToBeReplacedObjects },
						method: "PUT"
					};
				}
			})
		};
	}
});

export const {
	useGetFileMutation,
	useGetFilesMutation,
	useCreateFolderMutation,
	useDeleteFolderMutation,
	useDeleteFileMutation,
	useUploadFileMutation,
	useImportFilesMutation,
	useDownloadFileMutation,
	useExportFilesMutation,
	useRenameFileMutation,
	useRenameFolderMutation,
	useReplaceFilesMutation
} = storageManagementApiSlice;

import { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { AuthContext } from "context/AuthContext";
import ErrorBoundary from "common/ErrorBoundary";
import { mockedUnitIds, removedItemsFromMenu } from "utils/models";
import "./index.less";

function Sidebar({ collapsed, collapseHandler, match }) {
	const { location } = useHistory();
	const [{ appPermissions }] = useContext(AuthContext);
	const [openKeys, setOpenKeys] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);

	function detectSelectedKey() {
		const splitted = location.pathname.split("/").filter(i => i);
		const openedMenu = appPermissions
			?.map(item => item?.submenu)
			.flat()
			.find(sub => sub?.path === `${splitted[0]}/${splitted[1]}`);
		return openedMenu;
	}

	useEffect(() => {
		const selectedKey = detectSelectedKey();
		if (selectedKey) {
			setOpenKeys([`${selectedKey?.unitParentId}`]);
			setSelectedKeys([`${selectedKey?.unitId}`]);
		}
	}, [appPermissions, location]);

	const onOpenChange = keys => {
		setOpenKeys(keys);
	};

	const onClick = ({ key }) => {
		setSelectedKeys([key]);
	};

	const items = useMemo(() => {
		return (
			appPermissions
				?.filter(filterItem => {
					const children = filterItem.submenu?.filter(item => !removedItemsFromMenu.includes(item.unitId));
					return children.length > 0 && filterItem?.permissions.find(item => item.permissionId === 3 && !item.permit);
				})
				.map(menu => ({
					key: `${menu.unitId}`,
					label: menu.unitName,
					icon: (
						<span className="sidebar-icon">
							{typeof menu.icon === "string" ? <i className={`icon-${menu.icon}`} /> : menu.icon}
						</span>
					),
					children: menu.submenu
						?.filter(filtersubMenu => {
							if (filtersubMenu.unitId === mockedUnitIds.PermissionGroups) {
								return filtersubMenu.unitName && filtersubMenu.permissions.find(item => item.permissionId === 3);
							}
							return (
								!removedItemsFromMenu.includes(filtersubMenu.unitId) &&
								filtersubMenu.unitName &&
								filtersubMenu.permissions.find(item => item.permissionId === 3 && !item.permit)
							);
						})
						.map(subMenu => ({
							key: `${subMenu.unitId}`,
							label: <NavLink to={`${match.url}${subMenu.path}`}>{subMenu.unitName}</NavLink>
						}))
				})) || []
		);
	}, [appPermissions]);

	return (
		<ErrorBoundary>
			<Layout.Sider width={256} collapsed={collapsed} className="sidebar-main">
				<Menu
					mode="inline"
					selectedKeys={selectedKeys}
					openKeys={openKeys}
					style={{ height: "100%", borderRight: 0 }}
					className="custom-scroll"
					items={items}
					onOpenChange={onOpenChange}
					onClick={onClick}
				/>
				<div className="collapse-trigger">
					{collapsed ? (
						<MenuUnfoldOutlined className="trigger" onClick={collapseHandler} />
					) : (
						<MenuFoldOutlined className="trigger" onClick={collapseHandler} />
					)}
				</div>
			</Layout.Sider>
		</ErrorBoundary>
	);
}

Sidebar.defaultProps = {
	collapsed: false,
	collapseHandler: () => {},
	match: {}
};

Sidebar.propTypes = {
	collapsed: PropTypes.bool,
	collapseHandler: PropTypes.func,
	match: PropTypes.object
};
export default withRouter(Sidebar);

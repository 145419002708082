import PropTypes from "prop-types";
import { modalTypes } from "./constants";

export function ModalContent({ eventType, status, nickName, id }) {
	let content;
	switch (eventType) {
		case modalTypes.influence: {
			content = (
				<>
					<p>Are you sure you want to {status === "active" ? "deactivate" : "activate"}</p>
					<p>
						<strong>{nickName || id || ""}</strong>?
					</p>
				</>
			);
			break;
		}
		case modalTypes.block: {
			content = (
				<>
					<p>Are you sure you want to {status === 0 ? "Block" : "Unblock"}</p>
					<p>
						<strong>{nickName || id || ""}</strong> user?
					</p>
				</>
			);
			break;
		}
		case modalTypes.reset: {
			content = (
				<>
					<p>Are you sure you want to reset</p>
					<p>
						<strong>{nickName || id || ""}</strong> nickname?
					</p>
				</>
			);
			break;
		}
		default: {
			content = "";
		}
	}

	return <div className="text-center mt-24">{content}</div>;
}

ModalContent.defaultProps = {
	eventType: "",
	nickName: "",
	status: "",
	id: ""
};

ModalContent.propTypes = {
	eventType: PropTypes.string,
	nickName: PropTypes.string,
	status: PropTypes.string,
	id: PropTypes.string
};

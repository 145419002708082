import { useMemo } from "react";
import { Tabs } from "antd";
import PropTypes from "prop-types";
import { CurrencyRate } from "./CurrencyRate";
import { Precision } from "./Precision";

const ModalTabs = ({ onClose }) => {
	const memoItems = useMemo(() => [
		{
			key: "1",
			label: "Currency Rate",
			children: (
				<div className="tabChildrenContainer">
					<CurrencyRate onClose={onClose} />
				</div>
			)
		},
		{
			key: "2",
			label: "Precision",
			children: (
				<div className="tabChildrenContainer">
					<Precision onClose={onClose} />
				</div>
			)
		}
	]);

	return (
		<div className="rateModal">
			<Tabs defaultActiveKey="1" items={memoItems} />
		</div>
	);
};

ModalTabs.defaultProps = {
	onClose: () => {}
};

ModalTabs.propTypes = {
	onClose: PropTypes.func
};

export default ModalTabs;

import ErrorBoundary from "common/ErrorBoundary";
import { Button } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useModal } from "hooks/useModal";
import CustomModal from "common/Modal";
import Permitted from "common/Permitted";
import { mockedUnitIds } from "utils/models";
import { useGetPartnerCurrenciesQuery } from "app/services/partnersApiSlice";
import { updatePartnersCurrencies } from "app/slices/currenciesSlice";
import ModalTabs from "./ModalTabs";
import "./index.less";

function HeaderProviders() {
	const dispatch = useDispatch();
	const { data: partnersCurrencies } = useGetPartnerCurrenciesQuery();
	const [modals, openModal, closeModal] = useModal();

	useEffect(() => {
		dispatch(updatePartnersCurrencies(partnersCurrencies));
	}, [partnersCurrencies]);

	const handleCloseModal = useCallback(() => {
		closeModal("currency");
	}, []);

	return (
		<ErrorBoundary>
			<div className="header-providers">
				<Permitted parentUnitId={mockedUnitIds.Configuration} actionId={2} unitId={mockedUnitIds.Currencies}>
					<Button type="primary" className="rates-btn" onClick={() => openModal("currency")}>
						Rates
					</Button>
				</Permitted>

				{modals.currency?.open ? (
					<CustomModal
						open
						onCancel={handleCloseModal}
						centered
						modalWidth={832}
						bodyStyle={{ padding: "0 0 20px" }}
						body={<ModalTabs onClose={handleCloseModal} />}
						footer={null}
					/>
				) : null}
			</div>
		</ErrorBoundary>
	);
}

export default HeaderProviders;

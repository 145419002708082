import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import PropTypes from "prop-types";
import { isPermitted } from "utils/helpers";

export default function Permitted({ parentUnitId, actionId, unitId, children }) {
	const [{ appPermissions }] = useContext(AuthContext);
	const submenus = appPermissions?.find(submenu => submenu.unitId === parentUnitId)?.submenu;
	const submenuPermissions = submenus?.find(submenu => submenu.unitId === unitId)?.permissions;

	return submenuPermissions && isPermitted(submenuPermissions, actionId) ? children : null;
}

Permitted.defaultProps = {
	parentUnitId: 0,
	actionId: 0,
	unitId: 0,
	children: null
};

Permitted.propTypes = {
	parentUnitId: PropTypes.any,
	actionId: PropTypes.any,
	unitId: PropTypes.any,
	children: PropTypes.any
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataWithPagination, prepareHeaders } from "utils/helpers";
import {
	ActivateConfiguration,
	ActivateReward,
	ActiveInactiveCampaign,
	CreateCategory,
	CreateConfiguration,
	CreateReward,
	DeactivateConfiguration,
	DeactivateReward,
	DeleteCategory,
	EditCategory,
	EditConfiguration,
	EditReward,
	GetCampaign,
	GetCampaignsFilter,
	GetCategories,
	GetCategory,
	GetConfiguration,
	GetConfigurationFilters,
	GetConfigurations,
	GetCreateCampaignFilters,
	GetCreateConfigurationFilters,
	GetEditCampaign,
	GetLanguages,
	GetReward,
	GetRewardFilters,
	GetRewards,
	Missions,
	OrderCategories,
	GetPlayersFilter,
	CreateCompaign,
	GetCampaigns,
	EditCompaign
} from "../constants";

export const missionsSlice = createApi({
	reducerPath: "missions",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${Missions}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getCampaignsFilter: query({
				query: () => GetCampaignsFilter
			}),
			getEditCampaign: query({
				query: id => `${GetEditCampaign}?id=${id}`
			}),
			activeInactiveCampaign: mutation({
				query: params => ({
					url: ActiveInactiveCampaign,
					method: "POST",
					params
				})
			}),
			getConfigurationFilters: query({
				query: () => GetConfigurationFilters
			}),
			getConfigurations: mutation({
				query: body => ({
					url: GetConfigurations,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			activateConfiguration: mutation({
				query: id => ({
					url: `${ActivateConfiguration}/${id}`,
					method: "POST"
				})
			}),
			deactivateConfiguration: mutation({
				query: id => ({
					url: `${DeactivateConfiguration}/${id}`,
					method: "POST"
				})
			}),
			getCreateConfigurationFilters: query({
				query: () => GetCreateConfigurationFilters
			}),
			createConfiguration: mutation({
				query: body => ({
					url: CreateConfiguration,
					method: "POST",
					body
				})
			}),
			editConfiguration: mutation({
				query: body => ({
					url: EditConfiguration,
					method: "POST",
					body
				})
			}),
			getConfiguration: query({
				query: id => `${GetConfiguration}?id=${id}`
			}),
			getCategories: query({
				query: () => GetCategories
			}),
			orderCategories: mutation({
				query: body => ({
					url: OrderCategories,
					method: "POST",
					body
				})
			}),
			getCategory: query({
				query: id => `${GetCategory}?id=${id}`
			}),
			deleteCategory: mutation({
				query: id => ({
					url: `${DeleteCategory}/${id}`,
					method: "DELETE"
				})
			}),
			editCategory: mutation({
				query: body => ({
					url: EditCategory,
					method: "POST",
					body
				})
			}),
			createCategory: mutation({
				query: body => ({
					url: CreateCategory,
					method: "POST",
					body
				})
			}),
			getLanguages: query({
				query: () => GetLanguages
			}),
			getRewardFilters: query({
				query: () => GetRewardFilters
			}),
			getRewards: mutation({
				query: body => ({
					url: GetRewards,
					method: "POST",
					body
				})
			}),
			activateReward: mutation({
				query: id => ({
					url: `${ActivateReward}/${id}`,
					method: "POST"
				})
			}),
			deactivateReward: mutation({
				query: id => ({
					url: `${DeactivateReward}/${id}`,
					method: "POST"
				})
			}),
			createReward: mutation({
				query: body => ({
					url: CreateReward,
					method: "POST",
					body
				})
			}),
			getReward: query({
				query: id => `${GetReward}?id=${id}`
			}),
			editReward: mutation({
				query: body => ({
					url: EditReward,
					method: "POST",
					body
				})
			}),
			getCreateCampaignFilters: query({
				query: () => GetCreateCampaignFilters
			}),
			getCampaign: query({
				query: id => `${GetCampaign}?id=${id}`
			}),
			getPlayersFilter: query({
				query: () => GetPlayersFilter
			}),
			createCompaign: mutation({
				query: body => ({
					url: CreateCompaign,
					method: "POST",
					body
				})
			}),
			getCampaigns: mutation({
				query: body => ({
					url: GetCampaigns,
					method: "POST",
					body
				})
			}),
			editCompaign: mutation({
				query: body => ({
					url: EditCompaign,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useGetCampaignsFilterQuery,
	useActiveInactiveCampaignMutation,
	useGetEditCampaignQuery,
	useGetConfigurationFiltersQuery,
	useGetConfigurationsMutation,
	useActivateConfigurationMutation,
	useDeactivateConfigurationMutation,
	useGetCreateConfigurationFiltersQuery,
	useCreateConfigurationMutation,
	useGetConfigurationQuery,
	useEditConfigurationMutation,
	useGetCategoriesQuery,
	useOrderCategoriesMutation,
	useGetCategoryQuery,
	useDeleteCategoryMutation,
	useEditCategoryMutation,
	useCreateCategoryMutation,
	useGetLanguagesQuery,
	useGetRewardFiltersQuery,
	useGetRewardsMutation,
	useActivateRewardMutation,
	useDeactivateRewardMutation,
	useCreateRewardMutation,
	useGetRewardQuery,
	useEditRewardMutation,
	useGetCreateCampaignFiltersQuery,
	useGetCampaignQuery,
	useGetPlayersFilterQuery,
	useCreateCompaignMutation,
	useGetCampaignsMutation,
	useEditCompaignMutation
} = missionsSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataWithPagination, prepareHeaders } from "utils/helpers";
import { ALERT, GET_ALERT_EMAILS_FILTERS, GET_ANALYTICS, GET_ANALYTICS_FILTERS } from "../constants";

export const alertsApiSlice = createApi({
	reducerPath: "riskAnalytics",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${ALERT}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getAnalyticsFilter: query({
				query: () => ({
					url: GET_ANALYTICS_FILTERS,
					method: "GET"
				})
			}),
			getEmailsFilter: query({
				query: () => ({
					url: GET_ALERT_EMAILS_FILTERS,
					method: "GET"
				})
			}),
			getAnalyticsList: mutation({
				query: body => ({
					url: GET_ANALYTICS,
					method: "POST",
					body: dataWithPagination(body)
				})
			})
		};
	}
});

export const { useGetAnalyticsFilterQuery, useGetAnalyticsListMutation } = alertsApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
	ActivateInactivateServiceGroupSettings,
	ActivateInactivateServiceSettings,
	ActivateInactivateServiceTypeSettings,
	ActivateInactivateSettings,
	ActiveInactiveService,
	CreateActiveGame,
	CreateOrUpdate,
	CreateService,
	CreateServiceGroup,
	CreateServiceGroupSettings,
	CreateServiceSettings,
	CreateServiceType,
	CreateServiceTypeSettings,
	DeleteActiveGame,
	DeleteServiceGroup,
	DeleteServiceType,
	DEV_PAGE,
	EditActiveGame,
	EditServiceGroupSettings,
	EditServiceSettings,
	EditServiceTypeSettings,
	GetActiveGame,
	GetActiveGames,
	GetDevPageFilters,
	GetServiceById,
	GetServiceGroups,
	GetServiceGroupSettingsByFilter,
	GetServiceGroupSettingsById,
	GetServiceList,
	GetServiceSettingsByFilter,
	GetServiceSettingsById,
	GetServiceTypes,
	GetServiceTypeSettingsByFilter,
	GetServiceTypeSettingsById,
	GetSettingsByFilter,
	UpdateService
} from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const devPageSlice = createApi({
	reducerPath: "devPage",
	tagTypes: ["type", "group", "setting", "filters", "activeRTP"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}api/${DEV_PAGE}/`,
		prepareHeaders: headers => prepareHeaders(headers, { "Content-type": "application/json" })
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getServiceTypes: query({
				query: () => GetServiceTypes,
				providesTags: ["type"]
			}),
			deleteServiceType: mutation({
				query: body => ({
					url: DeleteServiceType,
					method: "DELETE",
					body
				}),
				invalidatesTags: () => ["type", "filters"]
			}),
			createServiceType: mutation({
				query: body => ({
					url: CreateServiceType,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["type", "filters"]
			}),
			getServiceGroups: query({
				query: () => GetServiceGroups,
				providesTags: ["group"]
			}),
			deleteServiceGroup: mutation({
				query: body => ({
					url: DeleteServiceGroup,
					method: "DELETE",
					body
				}),
				invalidatesTags: () => ["group", "filters"]
			}),
			createServiceGroup: mutation({
				query: body => ({
					url: CreateServiceGroup,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["group", "filters"]
			}),
			getServiceList: mutation({
				query: body => ({
					url: GetServiceList,
					method: "POST",
					body
				}),
				providesTags: ["service"]
			}),
			activeInactiveService: mutation({
				query: id => ({
					url: ActiveInactiveService,
					method: "DELETE",
					body: { id }
				}),
				invalidatesTags: () => ["service"]
			}),
			activateInactivateServiceGroupSettings: mutation({
				query: id => ({
					url: ActivateInactivateServiceGroupSettings,
					method: "DELETE",
					body: { id }
				})
			}),
			createService: mutation({
				query: body => ({
					url: CreateService,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["service", "filters"]
			}),
			updateService: mutation({
				query: body => ({
					url: UpdateService,
					method: "PUT",
					body
				}),
				invalidatesTags: () => ["service", "filters"]
			}),
			getServiceGroupSettingsByFilter: mutation({
				query: body => ({
					method: "POST",
					url: GetServiceGroupSettingsByFilter,
					body
				}),
				providesTags: ["setting"]
			}),
			getServiceTypeSettingsByFilter: mutation({
				query: body => ({
					url: GetServiceTypeSettingsByFilter,
					method: "POST",
					body
				}),
				providesTags: ["setting"]
			}),
			activateInactivateServiceTypeSettings: mutation({
				query: id => ({
					url: ActivateInactivateServiceTypeSettings,
					method: "DELETE",
					body: { id }
				})
			}),
			getServiceGroupSettingsById: query({
				query: id => `${GetServiceGroupSettingsById}?Id=${id}`,
				providesTags: ["setting"]
			}),
			getDevPageFilters: query({
				query: () => GetDevPageFilters,
				providesTags: ["filters"]
			}),
			editServiceGroupSettings: mutation({
				query: body => ({
					url: EditServiceGroupSettings,
					method: "PUT",
					body
				})
			}),
			createServiceGroupSettings: mutation({
				query: body => ({
					url: CreateServiceGroupSettings,
					method: "POST",
					body
				})
			}),
			editServiceTypeSettings: mutation({
				query: body => ({
					url: EditServiceTypeSettings,
					method: "PUT",
					body
				})
			}),
			createServiceTypeSettings: mutation({
				query: body => ({
					url: CreateServiceTypeSettings,
					method: "POST",
					body
				})
			}),
			getServiceTypeSettingsById: query({
				query: id => `${GetServiceTypeSettingsById}?Id=${id}`,
				providesTags: ["setting"]
			}),
			getSettingsByFilter: mutation({
				query: body => ({
					url: GetSettingsByFilter,
					method: "POST",
					body
				}),
				providesTags: ["setting"]
			}),
			activateInactivateSettings: mutation({
				query: body => ({
					url: ActivateInactivateSettings,
					method: "DELETE",
					body
				}),
				invalidatesTags: () => ["setting"]
			}),
			createOrUpdate: mutation({
				query: body => ({
					url: CreateOrUpdate,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["setting", "filters"]
			}),
			getServiceSettingsByFilter: mutation({
				query: body => ({
					url: GetServiceSettingsByFilter,
					method: "POST",
					body
				}),
				providesTags: ["service"]
			}),
			activateInactivateServiceSettings: mutation({
				query: body => ({
					url: ActivateInactivateServiceSettings,
					method: "DELETE",
					body
				}),
				invalidatesTags: () => ["service"]
			}),
			getServiceSettingsById: query({
				query: id => `${GetServiceSettingsById}?Id=${id}`,
				providesTags: ["service"]
			}),
			editServiceSettings: mutation({
				query: body => ({
					url: EditServiceSettings,
					method: "PUT",
					body
				})
			}),
			createServiceSettings: mutation({
				query: body => ({
					url: CreateServiceSettings,
					method: "POST",
					body
				})
			}),
			getServiceById: query({
				query: id => `${GetServiceById}?Id=${id}`,
				providesTags: ["service"]
			}),
			getActiveGame: query({
				query: id => `${GetActiveGame}?Id=${id}`,
				providesTags: ["activeRTP"]
			}),
			createActiveGame: mutation({
				query: body => ({
					url: CreateActiveGame,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["activeRTP", "filters"]
			}),
			editActiveGame: mutation({
				query: body => ({
					url: EditActiveGame,
					method: "PUT",
					body
				}),
				invalidatesTags: () => ["activeRTP"]
			}),
			deleteActiveGame: mutation({
				query: body => ({
					url: DeleteActiveGame,
					method: "DELETE",
					body
				}),
				invalidatesTags: () => ["activeRTP", "filters"]
			}),
			getActiveGames: mutation({
				query: body => ({
					url: GetActiveGames,
					method: "POST",
					body
				}),
				providesTags: ["activeRTP"]
			})
		};
	}
});

export const {
	useGetServiceTypesQuery,
	useDeleteServiceTypeMutation,
	useCreateServiceTypeMutation,
	useGetServiceGroupsQuery,
	useDeleteServiceGroupMutation,
	useCreateServiceGroupMutation,
	useGetServiceListMutation,
	useActiveInactiveServiceMutation,
	useActivateInactivateServiceGroupSettingsMutation,
	useCreateServiceMutation,
	useUpdateServiceMutation,
	useGetServiceGroupSettingsByFilterMutation,
	useGetServiceTypeSettingsByFilterMutation,
	useActivateInactivateServiceTypeSettingsMutation,
	useGetServiceGroupSettingsByIdQuery,
	useGetDevPageFiltersQuery,
	useEditServiceGroupSettingsMutation,
	useCreateServiceGroupSettingsMutation,
	useEditServiceTypeSettingsMutation,
	useCreateServiceTypeSettingsMutation,
	useGetServiceTypeSettingsByIdQuery,
	useGetSettingsByFilterMutation,
	useActivateInactivateSettingsMutation,
	useCreateOrUpdateMutation,
	useGetServiceSettingsByFilterMutation,
	useActivateInactivateServiceSettingsMutation,
	useGetServiceSettingsByIdQuery,
	useEditServiceSettingsMutation,
	useCreateServiceSettingsMutation,
	useGetServiceByIdQuery,
	useGetActiveGameQuery,
	useCreateActiveGameMutation,
	useEditActiveGameMutation,
	useDeleteActiveGameMutation,
	useGetActiveGamesMutation
} = devPageSlice;

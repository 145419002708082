import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout } from "antd";
import jwtDecode from "jwt-decode";
import storage from "utils/storage";
import { AuthContext } from "context/AuthContext";
import { mergeTwoMenu } from "utils/menu";
import ChangePassword from "components/Auth/ChangePassword";
import Sidebar from "components/Layout/Sidebar";
import Header from "components/Layout/Header";
import Content from "components/Layout/Content";
import Spinner from "common/Spinner";
import Auth from "../Auth";

function LayoutWrapper() {
	const [{ user, appPermissions }, dispatch] = useContext(AuthContext);
	const [processing, setProcessing] = useState(true);
	const [isAuth, setIsAuth] = useState(false);
	const [collapsed, setCollapsed] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;

	const collapseHandler = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		if (pathname !== "/signIn") storage?.setItem("pathname", pathname);
		const token = storage?.getItem("token");
		const permissions = storage?.getItem("permissions");
		const email = storage?.getItem("email");

		if (!Object.keys(user)?.length && permissions) {
			dispatch({ type: "SIGN_IN", payload: { ...jwtDecode(permissions), email } });
		}

		setIsAuth(!!token);
		setProcessing(false);
	}, [pathname]);

	useEffect(() => {
		const token = storage?.getItem("token");
		const storagePathname = storage?.getItem("pathname");
		if (token) {
			if (storagePathname) {
				history.push(storagePathname);
				return;
			}
			history.push("/");
		} else {
			switch (storagePathname) {
				case "/resetPassword":
				case "/setPassword":
				case "/emailConfirm":
					return history.push(location);
				default:
					return history.push(pathname);
			}
		}
	}, [user]);

	useEffect(() => {
		if (user?.Permissions && !appPermissions) {
			dispatch({ type: "SAVE_PERMISSIONS", payload: mergeTwoMenu(JSON.parse(user?.Permissions)) });
		}
	}, [user]);

	return processing ? (
		<Spinner />
	) : isAuth ? (
		<Layout>
			{pathname === "/changePassword" ? (
				<ChangePassword />
			) : (
				<>
					<Header collapsed={collapsed} />
					<Layout>
						<Sidebar collapsed={collapsed} collapseHandler={collapseHandler} />
						<Content />
					</Layout>
				</>
			)}
		</Layout>
	) : (
		<Auth />
	);
}

export default LayoutWrapper;

import { Spin } from "antd";
import "./index.css";

function Spinner() {
	return (
		<div className="spinner">
			<Spin />
		</div>
	);
}

export default Spinner;

import { Input } from "antd";
import PropTypes from "prop-types";
import "./index.less";

function NumberInput({ arrows, isInt, className, precisionLength, maxNumber, onChange, charLength, ...props }) {
	return (
		<Input
			{...props}
			className={`${arrows ? "" : "no-arrows"} ${className}`}
			onKeyDown={e => {
				const { key, target, ctrlKey } = e;
				if (key === "." && target.value.includes(".")) {
					e.preventDefault();
				}
				const dot = isInt ? [] : ["."];
				if (
					/\D/.test(key) &&
					!(
						["Backspace", "ArrowRight", "ArrowLeft", "Shift", "Tab", "Control", ...dot].includes(key) ||
						(ctrlKey && (key === "c" || key === "v" || key === "z"))
					)
				) {
					e.preventDefault();
				}
			}}
			onChange={e => {
				const { value, name } = e.target;
				if (charLength > 0 && value.length > charLength) {
					e.preventDefault();
					return;
				}
				if (maxNumber > 0 && +value > maxNumber) {
					onChange({
						target: {
							...e.target,
							name,
							value: maxNumber
						}
					});
					return;
				}
				const [val, prec] = value.split(".");
				if (prec?.length > precisionLength) {
					onChange({
						target: {
							...e.target,
							name,
							value: `${val}.${prec.substring(0, precisionLength)}`
						}
					});
					return;
				}
				if (isInt && value[0] === "0") {
					onChange({
						target: {
							...e.target,
							name,
							value: +value
						}
					});
				} else {
					onChange({ ...e, name, value, target: { name, value } });
				}
			}}
		/>
	);
}

NumberInput.defaultProps = {
	arrows: true,
	isInt: false,
	className: "",
	precisionLength: 9,
	charLength: 0,
	maxNumber: 0,
	onChange: () => {}
};

NumberInput.propTypes = {
	arrows: PropTypes.bool,
	isInt: PropTypes.bool,
	className: PropTypes.string,
	precisionLength: PropTypes.number,
	charLength: PropTypes.number,
	maxNumber: PropTypes.number,
	onChange: PropTypes.func
};

export default NumberInput;

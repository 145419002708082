import { memo } from "react";
import "./index.css";

function Dots() {
	return (
		<div className="dots-container">
			<span className="dots" />
			<span className="dots" />
			<span className="dots" />
		</div>
	);
}

export default memo(Dots);

/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { dataWithPagination, prepareHeaders } from "utils/helpers";
import {
	CAMPAIGN,
	CAMPAIGN_GET_LIST,
	GET_CAMPAIGNS_LIST_FILTERS,
	CAMPAIGN_ACTIVATE,
	CAMPAIGN_DEACTIVATE,
	GET_CAMPAIGN_TEMPLATE_FILTER
} from "../constants";

export const campaignApiSlice = createApi({
	reducerPath: "campaign",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${CAMPAIGN}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getCampaignFilter: query({
				query: () => ({
					url: GET_CAMPAIGNS_LIST_FILTERS,
					method: "POST"
				})
			}),
			getCampaignList: mutation({
				query: body => ({
					url: CAMPAIGN_GET_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			createCampaign: mutation({
				query: body => ({
					method: "POST",
					body
				})
			}),
			activateAllCampaigns: mutation({
				query: body => ({
					url: CAMPAIGN_ACTIVATE,
					method: "PUT",
					body
				})
			}),
			deactivateAllCampaigns: mutation({
				query: body => ({
					url: CAMPAIGN_DEACTIVATE,
					method: "PUT",
					body
				})
			}),
			updateCampaign: mutation({
				query: ({ id, body }) => {
					return {
						url: `${id}`,
						method: "PUT",
						body
					};
				}
			}),
			getCampaignTemplateFilters: query({
				query: () => {
					return {
						url: GET_CAMPAIGN_TEMPLATE_FILTER,
						method: "POST"
					};
				}
			}),
			getCampaignById: query({
				query: id => {
					return {
						url: `${id}`
					};
				}
			})
		};
	}
});

export const {
	useGetCampaignFilterQuery,
	useGetCampaignListMutation,
	useCreateCampaignMutation,
	useActivateAllCampaignsMutation,
	useDeactivateAllCampaignsMutation,
	useUpdateCampaignMutation,
	useGetCampaignTemplateFiltersQuery,
	useGetCampaignByIdQuery
} = campaignApiSlice;

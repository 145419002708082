/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
	ACTIVATE_GAMES_BY_FILTER,
	ACTIVATE_GAME_OF_PARTNER,
	DEACTIVATE_GAMES_BY_FILTER,
	DEACTIVATE_GAME_OF_PARTNER,
	EXIT_FROM_UNDER_CONSTUCTION_GAMES_OF_PARTNERS,
	EXIT_FROM_UNDER_CONSTUCTION_GAME_OF_PARTNER,
	GET_GAMES_LIST,
	GET_GAMES_LIST_FILTERS,
	GET_QUICK_BETS_OF_GAME,
	PARTNER,
	UNDER_CONSTUCTION_GAMES_OF_PARTNERS,
	UNDER_CONSTUCTION_GAME_OF_PARTNER,
	UPDATE_QUICK_BETS_OF_GAME_OF_PARTNER
} from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const gamesApiSlice = createApi({
	reducerPath: "games",
	tagTypes: ["getQuickBets"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${PARTNER}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getGamesListFilters: query({
				query: () => GET_GAMES_LIST_FILTERS,
				transformResponse: response => {
					response.gameTypes = response?.games;
					return response;
				}
			}),
			getGamesList: mutation({
				query: body => ({
					url: GET_GAMES_LIST,
					method: "POST",
					body
				})
			}),
			getQuickBetsOfGame: query({
				query: params => ({
					url: GET_QUICK_BETS_OF_GAME,
					params
				}),
				providesTags: ["getQuickBets"]
			}),
			updateQuickBetsOfGameOfPartner: mutation({
				query: body => ({
					url: UPDATE_QUICK_BETS_OF_GAME_OF_PARTNER,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["getQuickBets"]
			}),
			activateGameOfPartner: mutation({
				query: params => ({
					url: ACTIVATE_GAME_OF_PARTNER,
					method: "POST",
					params
				})
			}),
			deactivateGameOfPartner: mutation({
				query: params => ({
					url: DEACTIVATE_GAME_OF_PARTNER,
					method: "POST",
					params
				})
			}),
			activateGamesByFilter: mutation({
				query: body => ({
					url: ACTIVATE_GAMES_BY_FILTER,
					method: "POST",
					body
				})
			}),
			deactivateGamesByFilter: mutation({
				query: body => ({
					url: DEACTIVATE_GAMES_BY_FILTER,
					method: "POST",
					body
				})
			}),
			underConstuctionGamesOfPartners: mutation({
				query: body => ({
					url: UNDER_CONSTUCTION_GAMES_OF_PARTNERS,
					method: "POST",
					body
				})
			}),
			exitFromUnderConstuctionGamesOfPartners: mutation({
				query: body => ({
					url: EXIT_FROM_UNDER_CONSTUCTION_GAMES_OF_PARTNERS,
					method: "POST",
					body
				})
			}),
			underConstuctionGameOfPartner: mutation({
				query: body => ({
					url: UNDER_CONSTUCTION_GAME_OF_PARTNER,
					method: "POST",
					body
				})
			}),
			exitFromUnderConstuctionGameOfPartner: mutation({
				query: body => ({
					url: EXIT_FROM_UNDER_CONSTUCTION_GAME_OF_PARTNER,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useGetGamesListFiltersQuery,
	useGetGamesListMutation,
	useGetQuickBetsOfGameQuery,
	useUpdateQuickBetsOfGameOfPartnerMutation,
	useActivateGameOfPartnerMutation,
	useDeactivateGameOfPartnerMutation,
	useActivateGamesByFilterMutation,
	useDeactivateGamesByFilterMutation,
	useUnderConstuctionGamesOfPartnersMutation,
	useExitFromUnderConstuctionGamesOfPartnersMutation,
	useUnderConstuctionGameOfPartnerMutation,
	useExitFromUnderConstuctionGameOfPartnerMutation
} = gamesApiSlice;

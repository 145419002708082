import { useCallback } from "react";
import HeaderFilters from "common/HeaderFilters";
import { useFilters } from "hooks/useFilters";
import { useTable } from "hooks/useTable";
import { useGetAvatarsListFiltersQuery, useGetAvatarsListMutation } from "app/services/chutApiSlice";
import { tableFilters } from "./constants";
import StickersAvatars from "../StickersAvatars";

function Avatars() {
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] =
		useFilters(useGetAvatarsListFiltersQuery);
	const [getTableData, { isLoading, data, reset }] = useTable(useGetAvatarsListMutation, filtersQueryData);

	const handleReset = useCallback(() => {
		reset();
		setSelectedFiltersGroup({});
	}, [reset]);

	const handleApply = useCallback(
		() => getTableData({ partnerId: selectedFiltersGroup.partners }),
		[selectedFiltersGroup]
	);

	return (
		<section className="content-container pt-24">
			<HeaderFilters
				withReset
				withSubmit
				filtersComponentData={tableFilters}
				filtersQueryData={filtersQueryData}
				handleReset={handleReset}
				handleApply={handleApply}
				handleChange={handleSelectChange}
				selectedFiltersGroup={selectedFiltersGroup}
				setSelectedFiltersGroup={setSelectedFiltersGroup}
				disabledApplay={isLoading}
			/>
			<StickersAvatars type="avatars" files={data} selectedFiltersGroup={selectedFiltersGroup} />
		</section>
	);
}

export default Avatars;

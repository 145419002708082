import { memo, useState } from "react";
import moment from "moment";
import ErrorBoundary from "common/ErrorBoundary";
import logo from "assets/images/logo.svg";
import "./index.less";

function HeaderLeft() {
	const [time, setTime] = useState(moment().format("DD.MM.YYYY HH:mm [GMT]Z"));

	setInterval(() => {
		setTime(moment().format("DD.MM.YYYY HH:mm [GMT]Z"));
	}, 10000);

	return (
		<ErrorBoundary>
			<div className="align-center">
				<div className="logo-container">
					<div className="logo">
						<img src={logo} alt="Galaxsys" />
					</div>
				</div>
				<span className="current-date">{time}</span>
			</div>
		</ErrorBoundary>
	);
}

export default memo(HeaderLeft);

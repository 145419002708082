import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	OPERATOR,
	GET_FILTERS_FOR_CREATE_OPERATOR,
	GET_FILTERS_FOR_OPERATOR,
	GET_OPERATOR_LIST_BY_FILTER,
	ACTIVE_INACTIVE_USER,
	VIEW_OPERATOR,
	UPDATE_OPERATOR,
	RESET_QR,
	RESEND_EMAIL
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const operatorApiSlice = createApi({
	reducerPath: "operator",
	tagTypes: ["updateOperator"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}api/${OPERATOR}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getFiltersForCreateOperator: query({
				query: () => GET_FILTERS_FOR_CREATE_OPERATOR
			}),
			getFiltersForOperator: query({
				query: () => GET_FILTERS_FOR_OPERATOR
			}),
			getOperatorListByFilter: mutation({
				query: body => ({
					url: GET_OPERATOR_LIST_BY_FILTER,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			activInactiveUser: mutation({
				query: operatorId => ({
					url: `${ACTIVE_INACTIVE_USER}?operatorId=${operatorId}`,
					method: "POST"
				})
			}),
			getOperator: query({
				query: operatorId => `${VIEW_OPERATOR}?operatorId=${operatorId}`,
				providesTags: ["updateOperator"]
			}),
			updateOperator: mutation({
				query: body => ({
					url: UPDATE_OPERATOR,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["updateOperator"]
			}),
			resetQR: mutation({
				query: operatorId => ({
					url: `${RESET_QR}?operatorId=${operatorId}`,
					method: "POST"
				})
			}),
			resendEmail: mutation({
				query: id => ({
					url: `${RESEND_EMAIL}?operatorId=${id}`,
					method: "POST"
				})
			})
		};
	}
});

export const {
	useGetFiltersForCreateOperatorQuery,
	useGetFiltersForOperatorQuery,
	useGetOperatorListByFilterMutation,
	useActivInactiveUserMutation,
	useGetOperatorQuery,
	useUpdateOperatorMutation,
	useResetQRMutation,
	useResendEmailMutation
} = operatorApiSlice;

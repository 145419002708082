import { lazy, Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "common/Spinner";
import style from "./index.module.css";

const Login = lazy(() => import("./Login"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const SetPassword = lazy(() => import("./SetPassword"));
const ConfirmEmail = lazy(() => import("./ConfirmEmail"));

function Auth(props) {
	const { match } = props;

	return (
		<div className={style.main}>
			<Suspense fallback={<Spinner />}>
				<Switch>
					<Route exact path={`${match.url}signIn`} component={Login} />
					<Route exact path={`${match.url}forgotPassword`} component={ForgotPassword} />
					<Route path={`${match.url}setPassword`} component={SetPassword} />
					<Route path={`${match.url}resetPassword`} component={SetPassword} />
					<Route path={`${match.url}emailConfirm`} component={ConfirmEmail} />
					<Redirect to={`${match.url}signIn`} />
				</Switch>
			</Suspense>
			<span className={style.copyRight}>Copyright {new Date().getFullYear()}. All Rights Reserved</span>
		</div>
	);
}

Auth.defaultProps = {
	match: {}
};

Auth.propTypes = {
	match: PropTypes.object
};
export default withRouter(Auth);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	ACTIVE_INACTIVE_CASHBACK,
	CASHBACK,
	CREATE_CASHBACK,
	EDIT_CASHBACK,
	EXPORT_CASHBACK_PLAYERS_LIST,
	ExportCashBackReport,
	GET_CASHBACK,
	GET_CASHBACK_FILTERS,
	GET_CASHBACK_PLAYERS_LIST,
	GetCashBackReport,
	GetCashBackReportFilters
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const cashbackApiSlice = createApi({
	reducerPath: "cashback",
	tagTypes: ["edit", "create"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}api/${CASHBACK}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ mutation, query }) {
		return {
			getCashbackFilters: query({
				query: () => GET_CASHBACK_FILTERS,
				providesTags: ["create", "edit"]
			}),
			getCashback: mutation({
				query: body => ({
					url: GET_CASHBACK,
					method: "POST",
					body: dataWithPagination(body)
				}),
				providesTags: ["edit"]
			}),
			activeInactiveCashback: mutation({
				query: id => ({
					url: `${ACTIVE_INACTIVE_CASHBACK}?cashBackId=${id}`,
					method: "POST"
				})
			}),
			createCashback: mutation({
				query: body => ({
					url: CREATE_CASHBACK,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["create"]
			}),
			editCashback: mutation({
				query: body => ({
					url: EDIT_CASHBACK,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit"]
			}),
			getCashBackPlayersList: mutation({
				query: body => ({
					url: GET_CASHBACK_PLAYERS_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			exportCashBackPlayersList: mutation({
				query: body => ({
					url: EXPORT_CASHBACK_PLAYERS_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getCashBackReportFilters: query({
				query: () => GetCashBackReportFilters
			}),
			getCashBackReport: mutation({
				query: body => ({
					url: GetCashBackReport,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			exportCashBackReport: mutation({
				query: body => ({
					url: ExportCashBackReport,
					method: "POST",
					body: dataWithPagination(body)
				})
			})
		};
	}
});

export const {
	useGetCashbackFiltersQuery,
	useGetCashbackMutation,
	useActiveInactiveCashbackMutation,
	useCreateCashbackMutation,
	useEditCashbackMutation,
	useGetCashBackPlayersListMutation,
	useExportCashBackPlayersListMutation,
	useGetCashBackReportFiltersQuery,
	useGetCashBackReportMutation,
	useExportCashBackReportMutation
} = cashbackApiSlice;

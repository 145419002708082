import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	ACTIVATE_INACTIVATE,
	CREATE_LEADERBOARD,
	EDIT_LEADERBOARD,
	EXPORT_LEADERBOARD_PLAYERS,
	EXPORT_LEADERBOARD_REPORT,
	GET_CREATE_EDIT_FILTERS,
	GET_LEADERBOARD_BY_ID,
	GET_LEADERBOARD_PLAYERS,
	GET_LEADERBOARD_PLAYERS_FILTERS,
	GET_LEADERBOARD_REPORT,
	GET_LEADERBOARD_REPORT_FILTERS,
	GET_LEADERBOARDS,
	GET_LEADERBOARDS_LIST_FILTERS,
	LEADERBOARD
} from "../constants";
import { dataWithPagination, prepareHeaders } from "../../utils/helpers";

export const leaderboardApiSlice = createApi({
	reducerPath: "leaderboard",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${LEADERBOARD}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getLeaderboardsListFilters: query({
				query: () => GET_LEADERBOARDS_LIST_FILTERS
			}),
			getLeaderBoardPlayersFilter: query({
				query: () => GET_LEADERBOARD_PLAYERS_FILTERS
			}),
			getCreateEditFilters: query({
				query: () => GET_CREATE_EDIT_FILTERS
			}),
			getLeaderboardById: query({
				query: id => ({
					url: GET_LEADERBOARD_BY_ID,
					params: id
				})
			}),
			getLeaderboards: mutation({
				query: body => ({
					url: GET_LEADERBOARDS,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getLeaderboardPlayers: mutation({
				query: body => ({
					url: GET_LEADERBOARD_PLAYERS,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			createLeaderboard: mutation({
				query: body => ({
					url: CREATE_LEADERBOARD,
					method: "POST",
					body
				})
			}),
			editLeaderboard: mutation({
				query: body => ({
					url: EDIT_LEADERBOARD,
					method: "POST",
					body
				})
			}),
			activateInactivate: mutation({
				query: body => ({
					url: ACTIVATE_INACTIVATE,
					method: "POST",
					params: body
				})
			}),
			exportLeaderboardPlayers: mutation({
				query: body => ({
					url: EXPORT_LEADERBOARD_PLAYERS,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			exportLeaderboardReport: mutation({
				query: body => ({
					url: EXPORT_LEADERBOARD_REPORT,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getLeaderboardReport: mutation({
				query: body => ({
					url: GET_LEADERBOARD_REPORT,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getLeaderboardReportFilters: query({
				query: () => GET_LEADERBOARD_REPORT_FILTERS
			})
		};
	}
});
export const {
	useGetLeaderboardsListFiltersQuery,
	useGetLeaderBoardPlayersFilterQuery,
	useGetCreateEditFiltersQuery,
	useGetLeaderboardByIdQuery,
	useGetLeaderboardsMutation,
	useGetLeaderboardPlayersMutation,
	useCreateLeaderboardMutation,
	useEditLeaderboardMutation,
	useExportLeaderboardPlayersMutation,
	useExportLeaderboardReportMutation,
	useGetLeaderboardReportMutation,
	useGetLeaderboardReportFiltersQuery,
	useActivateInactivateMutation
} = leaderboardApiSlice;

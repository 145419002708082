import { Suspense, lazy } from "react";
import PropTypes from "prop-types";

const ImportModal = lazy(() => import("./ImportModal"));
const DirectoryChange = lazy(() => import("./DirectoryChange"));
const DeleteModal = lazy(() => import("./DeleteModal"));
const ViewModal = lazy(() => import("./ViewModal"));
const DuplicatesModal = lazy(() => import("./DuplicatesModal"));

function ModalWrapper({ closeModal, modals, openModal }) {
	return (
		<Suspense fallback={null}>
			{modals?.importModal?.open && (
				<ImportModal
					closeModal={closeModal}
					newPath={modals?.importModal?.newPath}
					type={modals?.importModal?.type}
					handleGetFiles={modals?.importModal?.handleGetFiles}
					openModal={openModal}
				/>
			)}
			{modals?.directoryChange?.open && (
				<DirectoryChange
					closeModal={closeModal}
					newPath={modals?.directoryChange?.newPath}
					handleGetFiles={modals?.directoryChange?.handleGetFiles}
					type={modals?.directoryChange?.type}
					renameType={modals?.directoryChange?.renameType}
					path={modals?.directoryChange?.path}
				/>
			)}
			{modals?.deleteModal?.open && (
				<DeleteModal
					closeModal={closeModal}
					deletePath={modals?.deleteModal?.deletePath}
					extension={modals?.deleteModal?.extension}
					handleGetFiles={modals?.deleteModal?.handleGetFiles}
				/>
			)}
			{modals?.viewModal?.open && (
				<ViewModal
					path={modals?.viewModal?.newPath}
					name={modals?.viewModal?.name}
					closeModal={closeModal}
					isImageType={modals?.viewModal?.isImageType}
				/>
			)}
			{modals?.duplicatesModal?.open && (
				<DuplicatesModal
					closeModal={closeModal}
					isZipType={modals?.duplicatesModal?.isZipType}
					filePath={modals?.duplicatesModal?.filePath}
					handleGetFiles={modals?.duplicatesModal?.handleGetFiles}
					replacedFilesPaths={modals?.duplicatesModal?.replacedFilesPaths}
					totalCount={modals?.duplicatesModal?.totalCount}
				/>
			)}
		</Suspense>
	);
}

ModalWrapper.defaultProps = {
	modals: {},
	closeModal: () => {},
	openModal: () => {}
};

ModalWrapper.propTypes = {
	modals: PropTypes.object,
	closeModal: PropTypes.func,
	openModal: PropTypes.func
};

export default ModalWrapper;

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, notification } from "antd";
import { ViewIcon } from "common/Icons";
import PropTypes from "prop-types";
import { memo, useState, useEffect } from "react";
import { dataURItoBlob } from "utils/helpers";

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const beforeUpload = file => {
	const isMyImage = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp";
	if (!isMyImage) {
		notification.error({ message: "You can only upload JPG/PNG/WEBP file!" });
	}
	return isMyImage;
};

function UploadFile({ imageData, classes, uploadImage, handlePreview, setFileList }) {
	const { id, url } = imageData;
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState(url);
	const [imageName, setImageName] = useState("");

	useEffect(() => {
		setImageUrl(url);
	}, [url]);

	const handleChange = info => {
		if (info.file.status === "uploading") {
			setLoading(true);
			return;
		}
		getBase64(info.file.originFileObj, blobUrl => {
			const formData = new FormData();
			const binaryFile = dataURItoBlob(blobUrl);
			binaryFile.fileName = binaryFile.type.split("/")[1];
			formData.append("file", binaryFile);
			uploadImage(formData)
				.then(res => {
					if (res.data?.imageURL) {
						// setImageUrl(res.data.imageURL);
						// setImageUrl(blobUrl);
						setImageName(info.file.name);
						setFileList(prev =>
							prev.map(item =>
								item.id === id
									? {
											...item,
											url: res.data.imageURL
									  }
									: item
							)
						);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		});
	};

	const removeImage = () => {
		setImageUrl("");
		setFileList(prev =>
			prev.map(item =>
				item.id === id
					? {
							...item,
							url: null
					  }
					: item
			)
		);
	};

	return (
		<div className={classes.upload}>
			<Upload
				name="avatar"
				listType="picture-card"
				showUploadList={false}
				beforeUpload={beforeUpload}
				onChange={handleChange}
				customRequest={({ onSuccess }) => onSuccess("ok")}
				accept="image/jpeg, image/png, image/webp"
			>
				{imageUrl ? (
					<img
						className={classes.img}
						src={imageUrl}
						alt="avatar"
						style={{
							width: "100%"
						}}
					/>
				) : (
					<div>
						{loading ? <LoadingOutlined /> : <PlusOutlined />}
						<div
							style={{
								marginTop: 8
							}}
						>
							Upload
						</div>
					</div>
				)}
			</Upload>
			{imageUrl && (
				<div className={classes.controls}>
					<ViewIcon onClick={() => handlePreview({ src: imageUrl, title: imageName })} />
					<DeleteOutlined onClick={removeImage} />
				</div>
			)}
		</div>
	);
}

UploadFile.defaultProps = {
	imageData: {},
	classes: {},
	uploadImage: () => {},
	handlePreview: () => {},
	setFileList: () => {}
};

UploadFile.propTypes = {
	imageData: PropTypes.object,
	classes: PropTypes.object,
	uploadImage: PropTypes.func,
	handlePreview: PropTypes.func,
	setFileList: PropTypes.func
};

export default memo(UploadFile);

import { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Table, notification } from "antd";
import { convertDateToLocalTimeZone } from "utils/helpers";
import { mappedColumns } from "../constants";
import HeaderActions from "./HeaderActions";

function ExpandableTable({
	isNested,
	name,
	getFiles,
	parentPath,
	openModal,
	isLoading,
	handleDownload,
	classNames,
	parrentRef
}) {
	const [{ dataSource, key }, setDataSource] = useState({ dataSource: [], key: 0 });
	const getChildFilesRef = useRef(null);
	const path = name ? (parentPath ? `${parentPath}/${name}` : `${name}`) : "";

	const handleGetFiles = () => {
		getFiles(path)
			.then(({ data }) => {
				const tableData = data?.map(item => ({
					...item,
					uploadDate: convertDateToLocalTimeZone({ date: item.uploadDate })
				}));

				setDataSource({ dataSource: tableData, key: Math.random() });
			})
			.catch(err => {
				notification.error({ message: err?.message || "Something went wrong." });
			});
	};

	const handleGetChildFiles = () => {
		getChildFilesRef.current?.();
	};

	const handleSetParentRef = useCallback(ref => {
		getChildFilesRef.current = ref;
	}, []);

	const expandedRowRender = args => (
		<ExpandableTable
			getFiles={getFiles}
			isNested
			classNames={classNames}
			handleDownload={handleDownload}
			parentPath={path}
			openModal={openModal}
			parrentRef={handleSetParentRef}
			{...args}
		/>
	);

	const columns = mappedColumns({
		isNested,
		path: path || "",
		openModal,
		handleDownload,
		classNames,
		handleGetFiles,
		handleGetChildFiles
	});

	useEffect(() => {
		handleGetFiles();
		parrentRef?.(handleGetFiles);
	}, []);

	return (
		<>
			{isNested || (
				<HeaderActions
					openModal={openModal}
					handleDownload={handleDownload}
					handleGetFiles={handleGetFiles}
					isLoading={isLoading}
				/>
			)}
			<Table
				key={key}
				columns={columns}
				loading={isLoading}
				expandable={{
					expandedRowRender,
					rowExpandable: ({ extension }) => extension === "Folder"
				}}
				dataSource={dataSource}
				pagination={false}
				bordered={false}
				showHeader={!isNested}
				className={isNested ? classNames.nested : ""}
				scroll={
					isNested
						? false
						: {
								x: "max-content",
								y: window.innerHeight - 300
						  }
				}
			/>
		</>
	);
}

ExpandableTable.defaultProps = {
	isNested: false,
	name: "",
	getFiles: () => {},
	parentPath: "",
	openModal: () => {},
	isLoading: false,
	handleDownload: () => {},
	classNames: {},
	parrentRef: () => {}
};

ExpandableTable.propTypes = {
	isNested: PropTypes.bool,
	name: PropTypes.string,
	getFiles: PropTypes.func,
	parentPath: PropTypes.string,
	openModal: PropTypes.func,
	isLoading: PropTypes.bool,
	handleDownload: PropTypes.func,
	classNames: PropTypes.object,
	parrentRef: PropTypes.func
};

export default memo(ExpandableTable);

/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
	EDIT_RULES,
	GET_RULES,
	GET_FILTERS,
	GET_RULE_BY_ID,
	GET_RULE_GUID,
	GET_RULES_VERSION_HISTORY,
	RULE,
	SAVE_RULES,
	UPLOAD_IMAGES
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const rulesApiSlice = createApi({
	reducerPath: "rules",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${RULE}/`,
		prepareHeaders
	}),
	tagTypes: ["ruleFilters"],
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getRulesFilter: query({
				query: ({ allPartners = false }) => ({
					url: GET_FILTERS,
					params: { allPartners }
				}),
				providesTags: ["ruleFilters"]
			}),
			getCreateRulesFilter: query({
				query: ({ allPartners = true }) => ({
					url: GET_FILTERS,
					params: { allPartners }
				})
			}),
			getRuleGuId: query({
				query: () => GET_RULE_GUID
			}),
			createRule: mutation({
				query: body => ({
					url: SAVE_RULES,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["ruleFilters"]
			}),
			editRule: mutation({
				query: body => ({
					url: EDIT_RULES,
					method: "POST",
					body
				})
			}),
			getRulesVersionHistory: mutation({
				query: body => ({
					url: GET_RULES_VERSION_HISTORY,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getRuleById: query({
				query: id => ({
					url: `${GET_RULE_BY_ID}?Id=${id}`,
					method: "GET"
				})
			}),
			getRules: mutation({
				query: body => ({
					url: GET_RULES,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),

			uploadImage: mutation({
				query: body => ({
					url: UPLOAD_IMAGES,
					method: "POST",
					body
				})
			})
		};
	}
});

export const {
	useGetRulesFilterQuery,
	useGetCreateRulesFilterQuery,
	useGetRulesMutation,
	useGetRulesVersionHistoryMutation,
	useCreateRuleMutation,
	useGetRuleGuIdQuery,
	useUploadImageMutation,
	useGetRuleByIdQuery,
	useEditRuleMutation
} = rulesApiSlice;

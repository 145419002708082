import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataWithPagination, prepareHeaders } from "utils/helpers";
import { MAX_WINNER, GET_MAX_WINNERS_FILTERS, GET_MAX_WINNERS_LIST } from "../constants";

export const maxWinnersApiSlice = createApi({
	reducerPath: "riskMaxWinners",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${MAX_WINNER}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getMaxWinnersFilters: query({
				query: () => ({
					url: GET_MAX_WINNERS_FILTERS,
					method: "GET"
				})
			}),
			getMaxWinnersList: mutation({
				query: body => ({
					url: GET_MAX_WINNERS_LIST,
					method: "POST",
					body: dataWithPagination(body)
				})
			})
		};
	}
});

export const { useGetMaxWinnersFiltersQuery, useGetMaxWinnersListMutation } = maxWinnersApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LOGGING, GET_USER_LOG_FILTERS, GET_USER_LOGS, GET_LOG_INFO } from "app/constants";
import { pageSize as PS } from "utils/constants";
import { prepareHeaders } from "utils/helpers";

function getDataWithPagination(url, params) {
	const {
		actionTypes,
		userId,
		startDate,
		endDate,
		pagination: { pageIndex = 0, pageSize = PS }
	} = params;
	let endpoint = `${url}?StartDate=${startDate}&EndDate=${endDate}&OperatorActionType=${actionTypes}&Pagination.PageIndex=${pageIndex}&Pagination.PageSize=${pageSize}`;
	if (userId) {
		endpoint += `&UserId=${userId}`;
	}
	return endpoint;
}

export const logsSlice = createApi({
	reducerPath: "logging",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${LOGGING}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getUserLogFilters: query({
				query: () => GET_USER_LOG_FILTERS
			}),
			getUserLogs: mutation({
				query: params => getDataWithPagination(GET_USER_LOGS, params)
			}),
			getLogInfo: mutation({
				query: logId => `${GET_LOG_INFO}?logId=${logId}`
			})
		};
	}
});

export const { useGetUserLogFiltersQuery, useGetUserLogsMutation, useGetLogInfoMutation } = logsSlice;

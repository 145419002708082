export default (() => {
	const uid = new Date();
	let storage;
	let result;
	try {
		(storage = window.localStorage).setItem(`${uid}`, `${uid}`);
		result = storage.getItem(`${uid}`) === `${uid}`;
		storage.removeItem(`${uid}`);
		return result && storage;
	} catch (ex) {
		console.log("Local Storage is Disabled!");
	}
})();

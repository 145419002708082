export const PLAYER_TYPES = {
	0: "Regular",
	1: "Influencer"
};
export const STATUS = {
	0: "Active",
	1: "Blocked"
};
export const MESSAGE_TYPE = {
	0: "Text",
	1: "Emoji",
	2: "ShareWin",
	3: "Gif"
};

import { DatePicker } from "antd";
import PropTypes from "prop-types";

const { RangePicker } = DatePicker;

function CustomRangePicker({
	onChange,
	showTime,
	rangePickerDate,
	disabledDate,
	picker,
	validator,
	name,
	key,
	customKey,
	disabled,
	form,
	status,
	format
}) {
	const handleChange = e => {
		onChange({ value: e || [], customKey, name }, "rangePicker", form);
		validator?.(e);
	};

	return (
		<RangePicker
			onChange={handleChange}
			disabled={disabled}
			name={name}
			getPopupContainer={trigger => trigger.parentElement}
			key={key}
			picker={picker}
			disabledDate={disabledDate}
			showTime={showTime}
			format={format}
			value={rangePickerDate}
			status={status}
		/>
	);
}

export default CustomRangePicker;

CustomRangePicker.defaultProps = {
	disabled: false,
	showTime: false,
	onChange: () => {},
	rangePickerDate: [],
	disabledDate: () => {},
	picker: "date",
	validator: () => {},
	form: {},
	name: "",
	key: "",
	customKey: "",
	status: "",
	format: ""
};

CustomRangePicker.propTypes = {
	disabled: PropTypes.any,
	showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	onChange: PropTypes.func,
	rangePickerDate: PropTypes.array,
	disabledDate: PropTypes.func,
	picker: PropTypes.string,
	validator: PropTypes.func,
	form: PropTypes.object,
	name: PropTypes.string,
	key: PropTypes.string,
	customKey: PropTypes.string,
	status: PropTypes.string,
	format: PropTypes.string
};

import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, notification, Table } from "antd";
import {
	useGetCurrenciesWithPrecisionMutation,
	useUpdateCurrenciesPrecisionMutation,
	useGetCurrenciesRatesFiltersQuery,
	useGetPartnerCurrenciesRatesFiltersQuery
} from "app/services/currencyApiSlice";
import { useFilters } from "hooks/useFilters";
import { useTable } from "hooks/useTable";
import { mockedActionIds, mockedUnitIds } from "utils/models";
import HeaderFilters from "common/HeaderFilters";
import Permitted from "common/Permitted";
import { precisionColumns, precisionFilters } from "./helpers";

export function Precision({ onClose }) {
	const [isEdit, setIsEdit] = useState(false);
	const [changedPrecisions, setChangedPrecisions] = useState({});
	const [currenciesPrecision, setCurrenciesPrecision] = useState([]);
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] = useFilters(
		useGetCurrenciesRatesFiltersQuery
	);
	const [getTableData, { isLoading, data }] = useTable(useGetCurrenciesWithPrecisionMutation, filtersQueryData);
	const [updateCurrenciesPrecision, { isLoading: idsLoading }] = useUpdateCurrenciesPrecisionMutation();

	const { data: currenciesByPartner } = useGetPartnerCurrenciesRatesFiltersQuery(selectedFiltersGroup.partners, {
		skip: !selectedFiltersGroup.partners
	});
	const partnerCurrencies = currenciesByPartner?.partnerCurrencies;

	const disabledSave = useMemo(() => {
		return (isEdit && Object.keys(changedPrecisions).length === 0) || isLoading || idsLoading;
	}, [changedPrecisions, isLoading, idsLoading, isEdit]);

	const precisionFiltersMemo = useMemo(
		() => precisionFilters(!partnerCurrencies || !selectedFiltersGroup.partners),
		[partnerCurrencies, selectedFiltersGroup.partners]
	);

	useEffect(() => {
		if (currenciesByPartner?.partnerCurrencies) {
			setSelectedFiltersGroup(prev => ({
				...prev,
				currencies: currenciesByPartner.partnerCurrencies.map(item => item.id)
			}));
		}
	}, [currenciesByPartner?.partnerCurrencies]);

	useEffect(() => {
		if (data?.currenciesWithPrecision) {
			setChangedPrecisions({});
			setCurrenciesPrecision(data?.currenciesWithPrecision || []);
		}
	}, [data]);

	useEffect(() => {
		setIsEdit(false);
		setChangedPrecisions({});
		setCurrenciesPrecision([]);
	}, [selectedFiltersGroup]);

	const handleApply = () => {
		getTableData({ currencyIds: selectedFiltersGroup.currencies, partnerId: selectedFiltersGroup.partners });
	};

	const handleReset = () => {
		setSelectedFiltersGroup({});
	};

	const editOrCancel = () => {
		if (isEdit) {
			setIsEdit(false);
			setChangedPrecisions({});
		} else {
			setIsEdit(true);
		}
	};

	const changeCoinRate = useCallback(e => {
		const { value, name } = e.target;
		setChangedPrecisions(prev => ({
			...prev,
			[name]: value
		}));
	}, []);

	const saveOrClose = () => {
		if (isEdit) {
			const requestData = [];
			Object.entries(changedPrecisions).forEach(([id, precision]) => {
				requestData.push({ id, precision });
			});
			updateCurrenciesPrecision({
				currenciesWithPrecision: requestData,
				partnerId: selectedFiltersGroup.partners
			}).then(res => {
				if (res.data?.hasError === false) {
					setChangedPrecisions({});
					handleApply();
					notification.success({ message: "Successfuly updated!" });
				}
			});
		} else {
			onClose();
		}
	};

	return (
		<>
			<div className="rateModal__form">
				<HeaderFilters
					withSubmit
					withReset
					filtersQueryData={{ ...filtersQueryData, currencies: partnerCurrencies }}
					filtersComponentData={precisionFiltersMemo}
					handleChange={handleSelectChange}
					handleReset={handleReset}
					handleApply={handleApply}
					selectedFiltersGroup={selectedFiltersGroup}
				/>
			</div>

			<div className="rateModal__table precision">
				<Table
					loading={isLoading}
					columns={precisionColumns({
						isEdit,
						currencies: partnerCurrencies,
						changeCoinRate,
						changedPrecisions
					})}
					dataSource={currenciesPrecision}
					pagination={false}
					rowKey="id"
					scroll={{
						y: 350
					}}
				/>
			</div>

			<div className="rateModal__buttons">
				<div className="ml-auto">
					<Permitted
						unitId={mockedUnitIds.ConfigurationGames}
						parentUnitId={mockedUnitIds.Configuration}
						actionId={mockedActionIds.edit}
					>
						<Button
							type="link"
							className="mr-16"
							onClick={editOrCancel}
							disabled={!currenciesPrecision.length || idsLoading}
						>
							{isEdit ? "Cancel" : "Edit"}
						</Button>
					</Permitted>
					<Button type="primary" onClick={saveOrClose} disabled={disabledSave}>
						{isEdit ? "Save" : "Close"}
					</Button>
				</div>
			</div>
		</>
	);
}

Precision.defaultProps = {
	onClose: () => {}
};

Precision.propTypes = {
	onClose: PropTypes.func
};

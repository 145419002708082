import { memo, useMemo, useState } from "react";
import { Checkbox, Select, Switch } from "antd";
import PropTypes from "prop-types";
import "./index.less";

function MultiSelect({
	placeholder,
	onChange,
	style,
	className,
	options,
	data,
	disabled,
	optionSwitch,
	maxTagCount,
	withSearch,
	name,
	status,
	form,
	isHideAllCheckbox,
	...props
}) {
	const [searchValue, setSearchValue] = useState("");
	const filteredOptions = useMemo(
		() =>
			options?.filter(
				item => (item.name || item.text || item.value)?.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
			),
		[searchValue, options]
	);
	const tagRender = label => {
		if (optionSwitch) {
			return <span className="ant-select-selection-placeholder">{placeholder}</span>;
		}

		return `${label?.props?.children}${maxTagCount !== 1 ? "," : ""}` || "";
	};

	let searchOptions = {};
	if (withSearch) {
		searchOptions = {
			showSearch: true,
			onSearch: setSearchValue
		};
	}

	return (
		<Select
			{...props}
			name={name}
			mode="multiple"
			placeholder={placeholder}
			className={`${className} custom-multi-select`}
			style={style}
			showArrow
			maxTagCount={optionSwitch ? 0 : maxTagCount}
			filterOption={false}
			getPopupContainer={trigger => trigger.parentElement}
			// disabled={disabled}
			value={data}
			tagRender={({ label }) => tagRender(label)}
			status={status}
			onBlur={() => setTimeout(() => setSearchValue(""), 10)}
			{...searchOptions}
		>
			{searchValue || optionSwitch || !filteredOptions?.length || isHideAllCheckbox ? null : (
				<Select.Option style={{ padding: 0, margin: 0 }} value="all" name="all">
					<Checkbox
						parentname={name}
						disabled={disabled}
						value="all"
						onChange={() => {
							return disabled
								? null
								: onChange(
										{
											target: {
												parentname: name,
												value: !(data?.length === options?.length) ? "all" : "",
												checked: !(data?.length === options?.length)
											}
										},
										"multiple",
										form,
										{ additionalData: { [name]: options } }
								  );
						}}
						checked={data?.length === options?.length}
						className="select-option"
					>
						All
					</Checkbox>
				</Select.Option>
			)}

			{filteredOptions?.map((item, index) => {
				const checked = data?.indexOf(item.id || item.value) !== -1;
				return (
					<Select.Option
						style={{ padding: 0, margin: 0 }}
						value={item.id}
						key={item.id || item.value || index}
						name={item.name || item.text || item.value}
						title={item.name}
					>
						{optionSwitch ? (
							<div className="select-option flex flex-between">
								<span>{item.name}</span>
								<Switch
									parentname={name}
									onChange={() =>
										disabled
											? null
											: onChange(
													{
														target: {
															value: item,
															checked: !checked
														}
													},
													"multiple",
													form
											  )
									}
									checkedChildren="ON"
									unCheckedChildren="OFF"
								/>
							</div>
						) : (
							<Checkbox
								value={item.id || item.value}
								key={item.id || item.value}
								name={item.text || item.name || item.value}
								parentname={name}
								disabled={disabled}
								onChange={e => (disabled ? null : onChange(e, "multiple", form))}
								checked={checked}
								className="select-option"
							>
								{item.name || item.text}
							</Checkbox>
						)}
					</Select.Option>
				);
			})}
		</Select>
	);
}

MultiSelect.defaultProps = {
	placeholder: "Select",
	className: "",
	name: "",
	style: {},
	options: [],
	data: [],
	onChange: () => {},
	disabled: false,
	optionSwitch: false,
	maxTagCount: 1,
	withSearch: true,
	status: "",
	form: { setFieldValue: () => {} },
	size: "middle",
	isHideAllCheckbox: false
};

MultiSelect.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	style: PropTypes.object,
	options: PropTypes.array,
	data: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	optionSwitch: PropTypes.bool,
	maxTagCount: PropTypes.number,
	withSearch: PropTypes.bool,
	status: PropTypes.string,
	size: PropTypes.string,
	form: PropTypes.object,
	isHideAllCheckbox: PropTypes.bool
};

export default memo(MultiSelect);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataWithPagination, prepareHeaders } from "utils/helpers";
import { RISKY_PLAYERS_LIST, READ_ALL, FILTERS } from "../constants";

export const riskPlayersListApiSlice = createApi({
	reducerPath: "riskPlayersList",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${RISKY_PLAYERS_LIST}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getPlayersFilter: query({
				query: () => ({
					url: FILTERS,
					method: "GET"
				})
			}),
			getPlayersList: mutation({
				query: body => ({
					url: READ_ALL,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			deletePlayersList: mutation({
				query: id => {
					return {
						url: `${id}`,
						method: "DELETE"
					};
				}
			}),
			getPlayersListConfig: query({
				query: id => {
					return {
						url: `${id}`,
						method: "GET"
					};
				}
			}),
			getPlayersListMembers: query({
				query: id => {
					return {
						url: `${id}/Members`,
						method: "GET"
					};
				}
			}),
			createPlayersList: mutation({
				query: body => {
					return {
						method: "POST",
						body
					};
				}
			}),
			exportPlayersList: mutation({
				query: id => {
					return {
						url: `${id}/Export`,
						method: "GET"
					};
				}
			}),
			updatePlayersList: mutation({
				query: params => {
					const { id, body } = params;
					return {
						url: `${id}`,
						method: "PUT",
						body
					};
				}
			})
		};
	}
});

export const {
	useGetPlayersFilterQuery,
	useGetPlayersListMutation,
	useDeletePlayersListMutation,
	useGetPlayersListConfigQuery,
	useGetPlayersListMembersQuery,
	useCreatePlayersListMutation,
	useUpdatePlayersListMutation,
	useExportPlayersListMutation
} = riskPlayersListApiSlice;

/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
	PARTNER,
	GET_PARTNERS_LIST,
	GET_INSTANCES_FILTER,
	GET_PARTNER_STATUS_FILTER,
	ACTIVATE_PARTNER_BY_ID,
	DEACTIVATE_PARTNER_BY_ID,
	ACTIVATE_PARTNERS_BY_FILTER,
	DEACTIVATE_PARTNERS_BY_FILTER,
	GET_CURRENCIES,
	GET_GAME_TYPES_FILTER,
	CREATE_PARTNER,
	EDIT_PARTNER_INFO_AND_PRODUCTS,
	UPDATE_PARTNER_QUICK_BET,
	ADD_PARTNER_QUICK_BET,
	EDIT_PARTNER,
	GET_PARTNERS_LIST_FILTERS,
	CHECK_PARTNER_NAME_EXISTANCE,
	CHECK_INSTANCE_NAME_EXISTANCE,
	CREATE_2FA,
	GET_2FA_FILTERS,
	GET_2FA,
	EDIT_2FA,
	ACTIVE_INACTIVE_2FA,
	GET_PARTNERS_CURRENCIES,
	GetBonusIntegrationTypesFilter
} from "app/constants";
import { pageSize as PS } from "utils/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

function getEndpointWithPagination({ url, params }) {
	const {
		instances = null,
		partners = null,
		statuses = 3,
		pagination: { pageIndex = 0, pageSize = PS }
	} = params;
	let endpoint = `${url}?Status=${statuses}&Pagination.PageIndex=${pageIndex}&Pagination.PageSize=${pageSize}`;
	if (instances) {
		endpoint += `&InstanceId=${instances}`;
	}
	if (partners) {
		endpoint += `&PartnerId=${partners}`;
	}
	return endpoint;
}

export const partnersApiSlice = createApi({
	reducerPath: "partners",
	tagTypes: ["editPartner", "getInstancesFilter", "edit2fa", "getFilters"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${PARTNER}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getFilters: query({
				query: () => GET_PARTNERS_LIST_FILTERS,
				providesTags: ["getFilters", "editPartner"]
			}),
			getInstancesFilter: query({
				query: () => GET_INSTANCES_FILTER,
				providesTags: ["getInstancesFilter"]
			}),
			getPartnerStatusFilter: query({
				query: () => GET_PARTNER_STATUS_FILTER
			}),
			getPartnerCurrencies: query({
				query: () => GET_PARTNERS_CURRENCIES
			}),
			getPartnersList: mutation({
				query: params => getEndpointWithPagination({ url: GET_PARTNERS_LIST, params })
			}),
			getCurrencies: query({
				query: () => GET_CURRENCIES
			}),
			fetchActivatePartnerById: mutation({
				query: partnerId => ({
					url: ACTIVATE_PARTNER_BY_ID,
					method: "POST",
					body: { partnerId }
				})
			}),
			fetchDeactivatePartnerById: mutation({
				query: partnerId => ({
					url: DEACTIVATE_PARTNER_BY_ID,
					method: "POST",
					body: { partnerId }
				})
			}),
			fetchActivatePartnersByFilter: mutation({
				query: body => ({
					url: ACTIVATE_PARTNERS_BY_FILTER,
					method: "POST",
					body
				})
			}),
			fetchDeactivatePartnersByFilter: mutation({
				query: body => ({
					url: DEACTIVATE_PARTNERS_BY_FILTER,
					method: "POST",
					body
				})
			}),
			getGameTypesFilter: query({
				query: () => GET_GAME_TYPES_FILTER
			}),
			editPartner: query({
				query: PartnerId => `${EDIT_PARTNER}?PartnerId=${PartnerId}`,
				providesTags: ["editPartner", "getInstancesFilter"]
			}),
			createPartner: mutation({
				query: body => ({
					url: CREATE_PARTNER,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["getFilters", "getInstancesFilter"]
			}),
			updatePartnerMainInfo: mutation({
				query: body => ({
					url: EDIT_PARTNER_INFO_AND_PRODUCTS,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editPartner", "getInstancesFilter"]
			}),
			updatePartnerQuickBet: mutation({
				query: body => ({
					url: UPDATE_PARTNER_QUICK_BET,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editPartner"]
			}),
			addPartnerQuickBet: mutation({
				query: body => ({
					url: ADD_PARTNER_QUICK_BET,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editPartner"]
			}),
			checkPartnerNameExistance: mutation({
				query: partnerName => `${CHECK_PARTNER_NAME_EXISTANCE}?partnerName=${partnerName}`
			}),
			checkInstanceNameExistance: mutation({
				query: instanceName => `${CHECK_INSTANCE_NAME_EXISTANCE}?Name=${instanceName}`
			}),
			create2FA: mutation({
				query: body => ({
					url: CREATE_2FA,
					method: "POST",
					body
				})
			}),
			get2FA: mutation({
				query: body => ({
					url: GET_2FA,
					method: "POST",
					body: dataWithPagination(body)
				}),
				providesTags: ["edit2fa"]
			}),
			get2FaFilters: query({
				query: () => GET_2FA_FILTERS
			}),
			edit2FA: mutation({
				query: body => ({
					url: EDIT_2FA,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["edit2fa"]
			}),
			activeInactive2FA: mutation({
				query: rowId => ({
					url: `${ACTIVE_INACTIVE_2FA}?rowId=${rowId}`,
					method: "POST"
				})
			}),
			getBonusIntegrationTypesFilter: query({
				query: () => GetBonusIntegrationTypesFilter
			})
		};
	}
});

export const {
	useGetFiltersQuery,
	useGetInstancesFilterQuery,
	useGetPartnerStatusFilterQuery,
	useGetPartnerCurrenciesQuery,
	useGetPartnersListMutation,
	useGetCurrenciesQuery,
	useFetchActivatePartnerByIdMutation,
	useFetchDeactivatePartnerByIdMutation,
	useFetchActivatePartnersByFilterMutation,
	useFetchDeactivatePartnersByFilterMutation,
	useGetGameTypesFilterQuery,
	useEditPartnerQuery,
	useCreatePartnerMutation,
	useUpdatePartnerMainInfoMutation,
	useUpdatePartnerQuickBetMutation,
	useAddPartnerQuickBetMutation,
	useCheckPartnerNameExistanceMutation,
	useCheckInstanceNameExistanceMutation,
	useCreate2FAMutation,
	useGet2FAMutation,
	useGet2FaFiltersQuery,
	useEdit2FAMutation,
	useActiveInactive2FAMutation,
	useGetBonusIntegrationTypesFilterQuery
} = partnersApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
	CREATE_TRANSLATIONS_KEY,
	EXPORT_TRANSLATIONS,
	GET_TRANSLATIONS_FILTERS,
	GET_TRANSLATIONS,
	IMPORT_TRANSLATIONS,
	TRANSLATION,
	CREATE_TRANSLATION_BY_KEY,
	DELETE_TRANSLATION_BY_ID,
	UPDATE_TRANSLATION_BY_KEY
} from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const translationApiSlice = createApi({
	reducerPath: "translations",
	tagTypes: ["translationsList"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${TRANSLATION}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getTranslationFilters: query({
				query: () => GET_TRANSLATIONS_FILTERS,
				transformResponse: response => {
					response.gameTypes = response?.games;
					return response;
				}
			}),
			getTranslation: mutation({
				query: body => ({
					url: GET_TRANSLATIONS,
					method: "POST",
					body
				}),
				providesTags: ["translationsList"]
			}),
			exportTranslations: mutation({
				query: body => ({
					url: EXPORT_TRANSLATIONS,
					method: "POST",
					body
				})
			}),
			importTranslations: mutation({
				query: ({ queryParams, body }) => ({
					url: IMPORT_TRANSLATIONS,
					params: queryParams,
					method: "POST",
					body
				})
			}),
			createTranslationsKey: mutation({
				query: body => ({
					url: CREATE_TRANSLATIONS_KEY,
					method: "POST",
					body
				})
			}),
			createTranslationByKey: mutation({
				query: body => ({
					url: CREATE_TRANSLATION_BY_KEY,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["translationsList"]
			}),
			deleteTranslationById: mutation({
				query: body => ({
					url: DELETE_TRANSLATION_BY_ID,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["translationsList"]
			}),
			updateTranslationByKey: mutation({
				query: body => ({
					url: UPDATE_TRANSLATION_BY_KEY,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["translationsList"]
			})
		};
	}
});

export const {
	useGetTranslationFiltersQuery,
	useGetTranslationMutation,
	useExportTranslationsMutation,
	useImportTranslationsMutation,
	useCreateTranslationsKeyMutation,
	useDeleteTranslationByIdMutation,
	useCreateTranslationByKeyMutation,
	useUpdateTranslationByKeyMutation
} = translationApiSlice;

import { useCallback } from "react";
import TableWithPagination from "common/TableWithPagination";
import HeaderFilters from "common/HeaderFilters";
import { useTable } from "hooks/useTable";
import { useFilters } from "hooks/useFilters";
import {
	useGetMessagesListFiltersQuery,
	useGetMessagesListMutation,
	useUpdateMessageStateMutation
} from "app/services/chutApiSlice";
import { tableColumns, tableFilters } from "./constants";

function ChatMessages() {
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] =
		useFilters(useGetMessagesListFiltersQuery);
	const [getTableData, { isLoading, data, pageSize, pageIndex, handlePageChange, reset }] = useTable(
		useGetMessagesListMutation,
		filtersQueryData
	);
	const [updateMessageState] = useUpdateMessageStateMutation();

	const handleReset = useCallback(() => {
		reset();
		setSelectedFiltersGroup({});
	}, [reset]);

	const handleApply = () => {
		getTableData({
			pageIndex,
			chatIds: selectedFiltersGroup.chatNames,
			partnerPlayerId: selectedFiltersGroup.partnerPlayerId,
			profanityCount: +selectedFiltersGroup.profanityCounts || undefined
		});
	};

	const onUpdateMessageState = body => {
		updateMessageState(body).then(() => {
			handleApply();
		});
	};
	return (
		<section className="content-container pt-24">
			<HeaderFilters
				withReset
				withSubmit
				filtersComponentData={tableFilters}
				filtersQueryData={filtersQueryData}
				handleReset={handleReset}
				handleApply={handleApply}
				handleChange={handleSelectChange}
				selectedFiltersGroup={selectedFiltersGroup}
				setSelectedFiltersGroup={setSelectedFiltersGroup}
				disabledApplay={isLoading}
			/>

			<TableWithPagination
				isLoading={isLoading}
				columns={tableColumns(onUpdateMessageState)}
				data={data?.data}
				totalCount={data?.totalCount}
				pageSize={pageSize}
				currentPage={pageIndex}
				setCurrentPage={handlePageChange}
				scroll={{ y: 600 }}
				rowKey="id"
			/>
		</section>
	);
}

export default ChatMessages;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GET_SYSTEM_SETTINGS, SYSTEM_SETTINGS, UPDATE_SYSTEM_SETTINGS } from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const systemSettingsSlice = createApi({
	reducerPath: "systemSettings",
	tagTypes: ["updateSystemSettings"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${SYSTEM_SETTINGS}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getSystemSettings: query({
				query: () => GET_SYSTEM_SETTINGS,
				providesTags: ["updateSystemSettings"]
			}),
			updateSystemSettings: mutation({
				query: body => ({
					url: UPDATE_SYSTEM_SETTINGS,
					method: "POST",
					body
				}),
				invalidatesTags: ["updateSystemSettings"]
			})
		};
	}
});

export const { useGetSystemSettingsQuery, useUpdateSystemSettingsMutation } = systemSettingsSlice;

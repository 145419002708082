import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	CREATE_PERMISSION_GROUP,
	ENABLE_OR_DISABLE_PERMISSION_GROUP,
	GET_PERMISSION_GROUP,
	GET_PERMISSION_GROUP_BY_ID,
	GET_PERMISSION_GROUP_FILTERS,
	PERMISSION,
	UPDATE_PERMISSION_GROUP
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const permissionApiSlice = createApi({
	reducerPath: "permission",
	tagTypes: ["editPermission"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${PERMISSION}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			enableOrDisablePermissionGroup: mutation({
				query: body => ({
					url: ENABLE_OR_DISABLE_PERMISSION_GROUP,
					method: "POST",
					body
				})
			}),
			getPermissionGroupById: query({
				query: id => `${GET_PERMISSION_GROUP_BY_ID}?id=${id}`,
				providesTags: ["editPermission"]
			}),
			getPermissionGroup: mutation({
				query: body => ({
					url: GET_PERMISSION_GROUP,
					method: "POST",
					body: dataWithPagination(body)
				}),
				providesTags: ["editPermission"]
			}),
			createPermissionGroup: mutation({
				query: body => ({
					url: CREATE_PERMISSION_GROUP,
					method: "POST",
					body
				})
			}),
			updatePermissionGroup: mutation({
				query: body => ({
					url: UPDATE_PERMISSION_GROUP,
					method: "POST",
					body
				}),
				invalidatesTags: () => ["editPermission"]
			}),
			getPermissionGroupFilters: query({
				query: () => GET_PERMISSION_GROUP_FILTERS
			})
		};
	}
});

export const {
	useEnableOrDisablePermissionGroupMutation,
	useGetPermissionGroupByIdQuery,
	useGetPermissionGroupMutation,
	useCreatePermissionGroupMutation,
	useUpdatePermissionGroupMutation,
	useGetPermissionGroupFiltersQuery
} = permissionApiSlice;

import { useEffect } from "react";
import { AuthProvider } from "context/AuthContext";
import { activityWatcher } from "utils/helpers";
import LayoutWrapper from "components/Layout";
import { useSetLastActivityMutation } from "app/services/accountApiSlice";
import "./styles/index.less";

function App() {
	const [setLastActivity] = useSetLastActivityMutation();

	useEffect(() => {
		activityWatcher(setLastActivity);
	}, []);

	return (
		<AuthProvider>
			<div className="App">
				<LayoutWrapper />
			</div>
		</AuthProvider>
	);
}

export default App;

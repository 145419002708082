import { ExportOutlined, ImportOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import PageActionButton from "common/PageActionButton";
import PropTypes from "prop-types";
import { useState } from "react";
import { mockedActionIds, mockedUnitIds } from "utils/models";

function HeaderActions({ openModal, handleDownload, handleGetFiles, isLoading }) {
	const [openExportModal, setExportOpenModal] = useState(false);
	const openImportModal = () => openModal("importModal", { newPath: "", type: "zip", handleGetFiles });
	const openNewDirectory = () => openModal("directoryChange", { newPath: "", handleGetFiles, type: "directory" });
	const handleOpenModal = () => setExportOpenModal(prev => !prev);

	const exportRoot = () => {
		handleDownload({ path: "", name: "root", type: "zip" });
		handleOpenModal();
	};

	return (
		<div className="flex justify-end mt-24 mb-16 gap-16">
			<PageActionButton
				disabled={isLoading}
				actionId={mockedActionIds.add}
				parentUnitId={mockedUnitIds.ContentManagement}
				unitId={mockedUnitIds.StorageManagement}
				handleClick={openImportModal}
				text={
					<>
						<ImportOutlined /> Import
					</>
				}
				type="primary"
				ghost
			/>
			<PageActionButton
				disabled={isLoading}
				actionId={mockedActionIds.export}
				parentUnitId={mockedUnitIds.ContentManagement}
				unitId={mockedUnitIds.StorageManagement}
				handleClick={handleOpenModal}
				text={
					<>
						<ExportOutlined /> Export
					</>
				}
				type="primary"
				ghost
			/>
			<PageActionButton
				disabled={isLoading}
				actionId={mockedActionIds.add}
				parentUnitId={mockedUnitIds.ContentManagement}
				unitId={mockedUnitIds.StorageManagement}
				handleClick={openNewDirectory}
				text="+ New Directory"
			/>
			<Modal
				open={openExportModal}
				okText="Yes"
				onOk={exportRoot}
				okButtonProps={{ className: "cms-buttons" }}
				onCancel={handleOpenModal}
				closable={false}
				width={492}
				centered
				bodyStyle={{
					fontSize: "14px",
					textAlign: "center"
				}}
			>
				<div className="flex-column">
					<span>Are you sure you want export Root Folder?</span>
				</div>
			</Modal>
		</div>
	);
}

HeaderActions.defaultProps = {
	isLoading: false,
	openModal: () => {},
	handleDownload: () => {},
	handleGetFiles: () => {}
};

HeaderActions.propTypes = {
	isLoading: PropTypes.bool,
	openModal: PropTypes.func,
	handleDownload: PropTypes.func,
	handleGetFiles: PropTypes.func
};

export default HeaderActions;

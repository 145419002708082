import {
	DeleteOutlined,
	DownloadOutlined,
	EditOutlined,
	ExportOutlined,
	EyeFilled,
	FileAddFilled,
	FolderFilled,
	ImportOutlined,
	UnorderedListOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Tooltip } from "antd";
import moment from "moment";
import PageActionButton from "common/PageActionButton";
import { mockedActionIds, mockedUnitIds } from "utils/models";
import Permitted from "common/Permitted";

const handlePermisions = ({ actionId, children }) =>
	Permitted({
		parentUnitId: mockedUnitIds.ContentManagement,
		unitId: mockedUnitIds.StorageManagement,
		actionId,
		children
	});

export const mappedColumns = ({
	isNested,
	path,
	openModal,
	handleDownload,
	classNames,
	handleGetFiles,
	handleGetChildFiles
}) => [
	{
		key: "1",
		title: isNested ? "" : "Name",
		dataIndex: "name",
		width: 100,
		fixed: "left"
	},
	{
		key: "2",
		title: isNested ? "" : "Date Modified",
		dataIndex: "uploadDate",
		sorter: (a, b) => moment(a.uploadDate) - moment(b.uploadDate),
		width: 130,
		fixed: "left"
	},
	{
		key: "3",
		title: isNested ? "" : "Size",
		dataIndex: "size",
		width: 100,
		fixed: "left",
		render: size => (size ? <span>{`${size} KB`}</span> : "-"),
		sorter: (a, b) => moment(a.size) - moment(b.size)
	},
	{
		key: "4",
		title: isNested ? "" : "Extension",
		dataIndex: "extension",
		width: 100,
		fixed: "left"
	},
	{
		key: "5",
		title: isNested ? "" : "Operator",
		dataIndex: "operator",
		width: 180,
		fixed: "left"
	},
	{
		key: "6",
		fixed: "right",
		width: 50,
		title: isNested ? "" : <div className="flex justify-end">Actions</div>,
		render: ({ extension, name }) => {
			const filePath = path ? `${path}/${name}` : name;
			const isImageType = extension.split("/")[0] === "image";
			const handleDelete = () => openModal("deleteModal", { deletePath: filePath, extension, handleGetFiles });
			const handleNewFolder = () =>
				openModal("directoryChange", { newPath: filePath, handleGetFiles: handleGetChildFiles });
			const handleOpenView = () => openModal("viewModal", { newPath: filePath, name, isImageType });
			const handleAddFile = type =>
				openModal("importModal", { newPath: filePath, type, handleGetFiles: handleGetChildFiles });
			const handleDownloadFile = () => handleDownload({ path: filePath, name, type: "file" });
			const handleExportFies = () => handleDownload({ path: filePath, name, type: "zip" });
			const handleRenameFile = renameType =>
				openModal("directoryChange", { newPath: filePath, handleGetFiles, type: "rename", renameType, path });

			return (
				<div className="flex align-center justify-end ">
					{extension === "Folder" ? (
						<>
							<PageActionButton
								actionId={mockedActionIds.add}
								className={classNames.actionButton}
								parentUnitId={mockedUnitIds.ContentManagement}
								unitId={mockedUnitIds.StorageManagement}
								handleClick={() => handleAddFile("zip")}
								text={
									<Tooltip placement="top" title="Import">
										<ImportOutlined />
									</Tooltip>
								}
								type="text"
								size="middle"
							/>
							<PageActionButton
								actionId={mockedActionIds.add}
								className={classNames.actionButton}
								parentUnitId={mockedUnitIds.ContentManagement}
								unitId={mockedUnitIds.StorageManagement}
								handleClick={() => handleAddFile("file")}
								text={
									<Tooltip placement="top" title="Add File">
										<FileAddFilled />
									</Tooltip>
								}
								type="text"
								size="middle"
							/>
							<Dropdown
								menu={{
									items: [
										handlePermisions({
											actionId: mockedActionIds.edit,
											children: {
												label: "Rename",
												key: "1",
												icon: (
													<Button className={classNames.actionButton} size="middle" type="text">
														<EditOutlined />
													</Button>
												),
												onClick: () => handleRenameFile("folder")
											}
										}),
										handlePermisions({
											actionId: mockedActionIds.export,
											children: {
												label: "Export",
												key: "2",
												icon: (
													<Button className={classNames.actionButton} size="middle" type="text">
														<ExportOutlined />
													</Button>
												),
												onClick: handleExportFies
											}
										}),
										handlePermisions({
											actionId: mockedActionIds.add,
											children: {
												label: "Add Directory",
												key: "3",
												icon: (
													<Button className={classNames.actionButton} size="middle" type="text">
														<FolderFilled />
													</Button>
												),
												onClick: handleNewFolder
											}
										}),
										handlePermisions({
											actionId: mockedActionIds.edit,
											children: {
												label: "Delete",
												key: "4",
												icon: (
													<Button className={classNames.actionButton} size="middle" type="text">
														<DeleteOutlined />
													</Button>
												),
												onClick: handleDelete
											}
										})
									]
								}}
							>
								<UnorderedListOutlined />
							</Dropdown>
						</>
					) : (
						<>
							<PageActionButton
								actionId={mockedActionIds.edit}
								className={classNames.actionButton}
								parentUnitId={mockedUnitIds.ContentManagement}
								unitId={mockedUnitIds.StorageManagement}
								handleClick={() => handleRenameFile("file")}
								text={
									<Tooltip placement="top" title="Rename">
										<EditOutlined />
									</Tooltip>
								}
								type="text"
								size="middle"
							/>
							<PageActionButton
								actionId={mockedActionIds.export}
								className={classNames.actionButton}
								parentUnitId={mockedUnitIds.ContentManagement}
								unitId={mockedUnitIds.StorageManagement}
								handleClick={handleDownloadFile}
								text={
									<Tooltip placement="top" title="Download">
										<DownloadOutlined />
									</Tooltip>
								}
								type="text"
								size="middle"
							/>
							<PageActionButton
								actionId={mockedActionIds.view}
								className={classNames.actionButton}
								parentUnitId={mockedUnitIds.ContentManagement}
								unitId={mockedUnitIds.StorageManagement}
								handleClick={handleOpenView}
								text={
									<Tooltip placement="top" title="View">
										<EyeFilled />
									</Tooltip>
								}
								type="text"
								size="middle"
							/>
							<PageActionButton
								actionId={mockedActionIds.edit}
								className={classNames.actionButton}
								parentUnitId={mockedUnitIds.ContentManagement}
								unitId={mockedUnitIds.StorageManagement}
								handleClick={handleDelete}
								text={
									<Tooltip placement="top" title="Delete">
										<DeleteOutlined />
									</Tooltip>
								}
								type="text"
								size="middle"
							/>
						</>
					)}
				</div>
			);
		}
	}
];

export const acceptedFileTypes = [
	".atlas",
	".json",
	".webp",
	".png",
	".xml",
	".ttf",
	".woff",
	".eot",
	".svg",
	".woff2",
	".gif",
	".pdf",
	".mp3",
	".mp4",
	".wav",
	".ico",
	".jpg",
	".webm"
];

import { memo, Suspense, useMemo, useContext } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { AuthContext } from "context/AuthContext";

import { isPermitted } from "utils/helpers";

import Spinner from "common/Spinner";
import ErrorBoundary from "common/ErrorBoundary";
import { mockedUnitIds } from "utils/models";
import BreadCrumbs from "./BreadCrumb";
import "./index.css";

function ContentWrapper({ match }) {
	const [{ appPermissions }] = useContext(AuthContext);
	const mappedRoutes = useMemo(() => {
		const routes = [];
		const redirectRoute = { path: "", component: null };
		appPermissions?.forEach(menu => {
			menu.submenu.forEach((item, index) => {
				const isPermitRoute = item.permissions?.find(permision => permision.permissionId === 3 && !permision.permit);
				if (isPermitRoute || menu.unitId === mockedUnitIds.UserManagment) {
					if (!redirectRoute.component) {
						redirectRoute.component = item.component;
						redirectRoute.path = item.path;
					}

					routes.push(
						<Route key={item.path + index} exact path={`${match.url}${item.path}`} component={item.component} />
					);

					if (item.actions?.length) {
						item.actions.forEach((pageAction, i) => {
							const { actionId, path, component } = pageAction;

							if (isPermitted(item.permissions, +actionId)) {
								routes.push(<Route key={path + i} exact path={`${match.url}${path}`} component={component} />);
							}
						});
					}
				}
			});
		});

		return { routes, redirectRoute };
	}, [appPermissions]);

	return (
		<Layout className="content-container custom-scroll">
			<ErrorBoundary>
				<BreadCrumbs />
			</ErrorBoundary>
			<Layout.Content
				className="site-layout-background content-main"
				style={{ width: "100%", height: "80vh", overflowY: "scroll" }}
			>
				<Suspense fallback={<Spinner />}>
					<Switch>
						{mappedRoutes.routes}
						<Redirect
							exact
							to={`${match.url}${mappedRoutes.redirectRoute.path}`}
							component={mappedRoutes.redirectRoute.component}
						/>
					</Switch>
				</Suspense>
			</Layout.Content>
		</Layout>
	);
}

ContentWrapper.defaultProps = {
	match: {}
};

ContentWrapper.propTypes = {
	match: PropTypes.object
};

export default withRouter(memo(ContentWrapper));

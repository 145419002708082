import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LICENSE, GET_LICENSES } from "app/constants";
import { prepareHeaders } from "utils/helpers";

export const licenseSlice = createApi({
	reducerPath: "license",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${LICENSE}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query }) {
		return {
			getLicense: query({
				query: () => GET_LICENSES
			})
		};
	}
});

export const { useGetLicenseQuery } = licenseSlice;

import { Button, Col, Form, Row, notification } from "antd";
import Fieldset from "common/Fieldset";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useFilters } from "hooks/useFilters";
import HeaderFilters from "common/HeaderFilters";
import NumberInput from "common/NumberInput";
import {
	useCreateChatMutation,
	useEditChatMutation,
	useGetChatByIdQuery,
	useGetCreateEditFiltersQuery
} from "app/services/chutApiSlice";
import classes from "./config.module.less";
import { modalFilters } from "./constants";

export function ChatConfigModal({ id, isEdit, closeModal, handleApply }) {
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] =
		useFilters(useGetCreateEditFiltersQuery);
	const [coefficients, setCoefficients] = useState({});
	const [updateValidation, setUpdateValidation] = useState(false);
	const { data } = useGetChatByIdQuery(id, { skip: !isEdit });
	const [createChat, { isLoading: createLoading }] = useCreateChatMutation();
	const [editChat, { isLoading: editLoading }] = useEditChatMutation();

	useEffect(() => {
		if (data && isEdit) {
			setSelectedFiltersGroup({ chatName: data.name, partners: data.partnerIds, gameTypes: data.gameTypeIds });
		}
	}, [data]);

	useEffect(() => {
		setUpdateValidation(false);
	}, [selectedFiltersGroup, coefficients]);

	useEffect(() => {
		const gameTypes = filtersQueryData.gameTypes;
		const selectedGames = selectedFiltersGroup.gameTypes || [];
		const newCoefficients = {};
		const serverData = data?.configuration?.gameTypeConfigurations || [];

		selectedGames?.forEach(item => {
			const { name } = gameTypes?.find(game => game.id === +item) || {};
			if (coefficients[item]) {
				newCoefficients[item] = { ...coefficients[item], name };
			} else {
				newCoefficients[item] = {
					name,
					id: item,
					value: isEdit ? serverData.find(elem => elem.gameType === +item)?.minShareCoefficient || 0 : 0
				};
			}
		});

		setCoefficients(newCoefficients);
	}, [selectedFiltersGroup, filtersQueryData, data]);

	const changeCoefficient = e => {
		const { name, value } = e.target;
		setCoefficients(prev => ({
			...prev,
			[name]: {
				...prev[name],
				value
			}
		}));
	};

	const saveOrCreate = () => {
		const { gameTypes, partners, chatName } = selectedFiltersGroup;

		if (gameTypes && partners?.length && chatName) {
			const request = isEdit ? editChat : createChat;

			const gameTypeConfigurations = Object.values(coefficients).map(item => ({
				gameType: +item.id,
				minShareCoefficient: +item.value
			}));

			const requestData = {
				name: chatName,
				configuration: {
					gameTypeConfigurations
				},
				partnerIds: partners,
				gameTypeIds: gameTypes
			};
			if (isEdit) {
				requestData.id = id;
			}
			request(requestData).then(res => {
				if (res.data?.hasError === false) {
					handleApply();
					closeModal();
					notification.success({
						message: "Successfuly created!"
					});
				} else {
					notification.error({
						message: "Wrong Partner(s) Game(s) Combination"
					});
				}
			});
		} else {
			setUpdateValidation(true);
		}
	};

	return (
		<Form layout="vertical">
			<HeaderFilters
				filtersComponentData={modalFilters}
				filtersQueryData={filtersQueryData}
				handleChange={handleSelectChange}
				selectedFiltersGroup={selectedFiltersGroup}
				setSelectedFiltersGroup={setSelectedFiltersGroup}
				updateValidation={updateValidation}
			/>
			<Fieldset title="Win Coefficient To Be Shared" className={classes.fieldset}>
				<div className={classes.coefficients}>
					<Row gutter={[16, 16]}>
						{Object.entries(coefficients).map(([key, coefficient]) => (
							<Col span={8} key={key}>
								<NumberInput
									isInt
									maxNumber={700000}
									className="my-ant-input-group-addon"
									name={key}
									onChange={changeCoefficient}
									addonBefore={coefficient.name}
									value={coefficient.value}
								/>
							</Col>
						))}
					</Row>
				</div>
			</Fieldset>
			<Row gutter={[16, 16]} className={classes.footer}>
				<Col>
					<Button onClick={closeModal}>Cancel</Button>
				</Col>
				<Col>
					<Button type="primary" onClick={saveOrCreate} disabled={createLoading || editLoading}>
						{isEdit ? "Save" : "Create"}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}

ChatConfigModal.defaultProps = {
	id: 1,
	isEdit: false,
	closeModal: () => {},
	handleApply: () => {}
};

ChatConfigModal.propTypes = {
	id: PropTypes.number,
	isEdit: PropTypes.bool,
	closeModal: PropTypes.func,
	handleApply: PropTypes.func
};

import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, notification, Table } from "antd";
import {
	useGetCurrenciesRatesByFilterMutation,
	useGetCurrenciesRatesFiltersQuery,
	useEditCurrencyRateByCurrencyIdAndDateRangeMutation,
	useEditCurrencyRateByIdsMutation,
	useGetPartnerCurrenciesRatesFiltersQuery
} from "app/services/currencyApiSlice";
import { useFilters } from "hooks/useFilters";
import { useTable } from "hooks/useTable";
import { mockedActionIds, mockedUnitIds } from "utils/models";
import { convertDateToUserTimezone } from "utils/helpers";
import HeaderFilters from "common/HeaderFilters";
import Permitted from "common/Permitted";
import NumberInput from "common/NumberInput";
import Fieldset from "common/Fieldset";
import CustomRangePicker from "common/CustomRangePicker";
import { currencyColumns, currencyFilters, getDateForShowing, isInvalidRangeRate } from "./helpers";

export function CurrencyRate({ onClose }) {
	const [isEdit, setIsEdit] = useState(false);
	const [isRangeEdit, setIsRangeEdit] = useState(false);
	const [errors, setErrors] = useState({});
	const [changedRates, setChangedRates] = useState({});
	const [currenciesRates, setCurrenciesRates] = useState([]);
	const [filtersQueryData, { selectedFiltersGroup, setSelectedFiltersGroup }, handleSelectChange] = useFilters(
		useGetCurrenciesRatesFiltersQuery
	);
	const [getTableData, { isLoading, data }] = useTable(useGetCurrenciesRatesByFilterMutation, filtersQueryData);
	const [editCurrencyRateByCurrencyIdAndDateRange, { isLoading: rangeLoading }] =
		useEditCurrencyRateByCurrencyIdAndDateRangeMutation();
	const [editCurrencyRateByIds, { isLoading: idsLoading }] = useEditCurrencyRateByIdsMutation();

	const { data: currenciesByPartner } = useGetPartnerCurrenciesRatesFiltersQuery(selectedFiltersGroup.partners, {
		skip: !selectedFiltersGroup.partners
	});
	const partnerCurrencies = currenciesByPartner?.partnerCurrencies;

	const disabledSave = useMemo(() => {
		return ((isEdit || isRangeEdit) && Object.keys(changedRates).length === 0) || isLoading || idsLoading;
	}, [changedRates, isLoading, idsLoading, isEdit, isRangeEdit]);

	const currencyFiltersMemo = useMemo(
		() => currencyFilters(!partnerCurrencies || !selectedFiltersGroup.partners),
		[partnerCurrencies, selectedFiltersGroup.partners]
	);
	const currencyName = partnerCurrencies?.currencies?.find(item => item.id === selectedFiltersGroup.currencies)?.name;

	useEffect(() => {
		if (currenciesByPartner?.partnerCurrencies) {
			setSelectedFiltersGroup(prev => ({
				...prev,
				currencies: currenciesByPartner.partnerCurrencies[0]?.id
			}));
		}
	}, [currenciesByPartner?.partnerCurrencies]);

	useEffect(() => {
		if (data?.partnerCurrenciesRates) {
			setChangedRates({});
			const dates = data.partnerCurrenciesRates.map(item => ({
				...item,
				fromDate: convertDateToUserTimezone(item.fromDate).format("DD/MM/YYYY HH:mm:ss")
			}));
			setCurrenciesRates(dates || []);
		}
	}, [data]);

	const resetStates = useCallback(() => {
		setIsRangeEdit(false);
		setIsEdit(false);
		setChangedRates({});
		setCurrenciesRates([]);
		setErrors({});
	}, []);

	useEffect(() => {
		resetStates();
		if (selectedFiltersGroup.dateRange !== "5") {
			delete selectedFiltersGroup.startDate;
			delete selectedFiltersGroup.endDate;
		}
	}, [selectedFiltersGroup]);

	const handleApply = () => {
		const { startDate, endDate, dateRange, partners, currencies } = selectedFiltersGroup;
		getTableData({
			startDate,
			endDate,
			rangeType: +dateRange,
			partnerId: partners,
			currencyId: currencies
		});
	};

	const handleReset = () => {
		setSelectedFiltersGroup({});
	};

	const editSelectedRange = () => {
		if (selectedFiltersGroup.dateRange && selectedFiltersGroup.currencies) {
			setIsRangeEdit(true);
			setChangedRates({});
		}
	};

	const editOrCancel = () => {
		if (isRangeEdit || isEdit) {
			setIsRangeEdit(false);
			setIsEdit(false);
			setChangedRates({});
		} else {
			setIsEdit(true);
		}
	};

	const changeCoinRate = useCallback(
		e => {
			const { value, name } = e.target;
			setChangedRates(prev => ({
				...prev,
				[name]: value
			}));
			if (errors[name] === "error") {
				setErrors(prev => ({ ...prev, [name]: "" }));
			}
		},
		[errors]
	);

	const saveOrClose = () => {
		if (isEdit || isRangeEdit) {
			if (isRangeEdit) {
				const msg = isInvalidRangeRate(changedRates["0"]);
				if (msg) {
					setErrors({ 0: "error" });
					notification.error({ message: msg });
					return;
				}
				const requestData = {
					partnerId: selectedFiltersGroup.partners,
					currencyId: selectedFiltersGroup.currencies,
					rangeType: +selectedFiltersGroup.dateRange,
					rate: changedRates["0"]
				};
				if (selectedFiltersGroup.dateRange === "5") {
					requestData.startDate = selectedFiltersGroup.startDate;
					requestData.endDate = selectedFiltersGroup.endDate;
				}
				editCurrencyRateByCurrencyIdAndDateRange(requestData).then(res => {
					if (res.data?.hasError === false) {
						notification.success({ message: "Successfuly updated!" });
						resetStates();
						handleApply();
					}
				});
			} else {
				const requestData = [];
				let isError = false;
				const newErrors = {};
				Object.entries(changedRates).forEach(([id, coinRate]) => {
					requestData.push({ id, coinRate });
					const msg = isInvalidRangeRate(coinRate);
					if (msg) {
						notification.error({ message: msg });
						isError = true;
						newErrors[id] = "error";
					}
				});
				setErrors(newErrors);
				if (isError) return;
				editCurrencyRateByIds({
					rates: requestData,
					partnerId: selectedFiltersGroup.partners
				}).then(res => {
					if (res.data?.hasError === false) {
						handleApply();
						notification.success({ message: "Successfuly updated!" });
					}
				});
			}
		} else {
			onClose();
		}
	};

	return (
		<>
			<div className="rateModal__form">
				<HeaderFilters
					withSubmit
					withReset
					filtersQueryData={{ ...filtersQueryData, currencies: partnerCurrencies }}
					classname={selectedFiltersGroup.dateRange === "5" ? "rangeDate" : ""}
					filtersComponentData={
						selectedFiltersGroup.dateRange === "5"
							? [
									...currencyFiltersMemo,
									{
										Component: CustomRangePicker,
										help: "Please select Date/Range!",
										name: "rangePicker",
										label: "Date/Time Range",
										isRequired: true,
										formItemClassName: "medium",
										componentProps: {
											placeholder: "Date/Range",
											showTime: true
										}
									}
							  ]
							: currencyFiltersMemo
					}
					handleChange={handleSelectChange}
					handleReset={handleReset}
					handleApply={handleApply}
					selectedFiltersGroup={selectedFiltersGroup}
					disabledApplay={isLoading}
				/>
			</div>

			<div className="rateModal__table">
				<Table
					loading={isLoading}
					columns={currencyColumns({ currencyName, isEdit, isRangeEdit, changeCoinRate, changedRates, errors })}
					dataSource={currenciesRates}
					pagination={false}
					rowKey="id"
					scroll={{
						y: 350
					}}
				/>
			</div>

			{isRangeEdit && (
				<Fieldset title="Apply to Selected Range">
					<div className="fieldset__item">{getDateForShowing(selectedFiltersGroup)}</div>
					<div className="fieldset__item fieldset__item--input">
						<NumberInput
							precisionLength={10}
							value={changedRates["0"]}
							name="0"
							onChange={changeCoinRate}
							status={errors["0"]}
						/>
						{currencyName} = 1 EURO
					</div>
				</Fieldset>
			)}

			<div className="rateModal__buttons">
				{!isRangeEdit && isEdit && (
					<Permitted
						unitId={mockedUnitIds.Currencies}
						parentUnitId={mockedUnitIds.Configuration}
						actionId={mockedActionIds.edit}
					>
						<Button type="link" onClick={editSelectedRange}>
							<u>Edit Selected Range</u>
						</Button>
					</Permitted>
				)}
				<div className="ml-auto">
					<Permitted
						unitId={mockedUnitIds.Currencies}
						parentUnitId={mockedUnitIds.Configuration}
						actionId={mockedActionIds.edit}
					>
						<Button
							type="link"
							className="mr-16"
							onClick={editOrCancel}
							disabled={!currenciesRates.length || rangeLoading || idsLoading}
						>
							{isEdit || isRangeEdit ? "Cancel" : "Edit"}
						</Button>
					</Permitted>
					<Button type="primary" onClick={saveOrClose} disabled={disabledSave}>
						{isEdit || isRangeEdit ? "Save" : "Close"}
					</Button>
				</div>
			</div>
		</>
	);
}

CurrencyRate.defaultProps = {
	onClose: () => {}
};

CurrencyRate.propTypes = {
	onClose: PropTypes.func
};

import { useState, useEffect } from "react";

const browserPrefixes = ["moz", "ms", "o", "webkit"];

// get the correct attribute name
function getHiddenPropertyName(prefix) {
	return prefix ? `${prefix}Hidden` : "hidden";
}

// get the correct event name
function getVisibilityEvent(prefix) {
	return `${prefix || ""}visibilitychange`;
}

// get current browser vendor prefix
function getBrowserPrefix() {
	for (let i = 0; i < browserPrefixes.length; i++) {
		if (getHiddenPropertyName(browserPrefixes[i]) in document) {
			// return vendor prefix
			return browserPrefixes[i];
		}
	}
	// no vendor prefix needed
	return null;
}

// bind and handle events
const browserPrefix = getBrowserPrefix();
const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
const visibilityEventName = getVisibilityEvent(browserPrefix);

export default function useWindowVisibility() {
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		function handleVisibilityChange(forcedFlag) {
			// forcedFlag is a boolean when this event handler is triggered by a
			// focus or blur eventotherwise it's an Event object
			if (typeof forcedFlag === "boolean") {
				if (forcedFlag) {
					return setIsVisible(forcedFlag);
				}
			}

			setIsVisible(!document[hiddenPropertyName]);
		}

		function setWindowisBlured() {
			handleVisibilityChange(false);
		}
		function setWindowisFocused() {
			handleVisibilityChange(true);
		}

		document.addEventListener(visibilityEventName, handleVisibilityChange, false);
		document.addEventListener("focus", setWindowisFocused, false);
		document.addEventListener("blur", setWindowisBlured, false);
		window.addEventListener("focus", setWindowisFocused, false);
		window.addEventListener("blur", setWindowisBlured, false);

		return () => {
			document.removeEventListener(visibilityEventName, handleVisibilityChange);
			document.removeEventListener("focus", setWindowisFocused, false);
			document.removeEventListener("blur", setWindowisBlured, false);
			window.removeEventListener("focus", setWindowisFocused, false);
			window.removeEventListener("blur", setWindowisBlured, false);
		};
	}, []);
	return isVisible;
}
